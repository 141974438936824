var page_actions = function () {

    /* PROGGRESS START
    $.mpb("show",{value: [0,50],speed: 5});
     END PROGGRESS START */

    var html_click_avail = true;

    $("html").on("click", function () {
        if (html_click_avail)
            $(".x-navigation-horizontal li,.x-navigation-minimized li,.x-features .x-features-search,.x-features .x-features-profile").removeClass('active');
    });

    $(".x-features-nav-open").on("click", function (e) {
        $(".x-hnavigation").toggleClass("active");
    });

    $(".x-hnavigation .xn-openable > a").on("click", function (e) {
        if ($(this).parent("li").hasClass("active")) {
            $(this).parent("li").removeClass("active");
        } else {
            $(".x-hnavigation .xn-openable").removeClass("active");
            $(this).parents("li").addClass("active");
        }
    });

    $(".x-features .x-features-profile").on("click", function (e) {
        e.stopPropagation();
        $(this).toggleClass("active");
    });

    $(".x-features .x-features-search").on("click", function (e) {
        e.stopPropagation();
        $(this).addClass("active");
        $(this).find("input[type=text]").focus();
    });

    $(".x-navigation-horizontal .panel").on("click", function (e) {
        e.stopPropagation();
    });

    /* WIDGETS (DEMO)*/
    $(".widget-remove").on("click", function () {
        $(this).parents(".widget").fadeOut(400, function () {
            $(this).remove();
            $("body > .tooltip").remove();
        });
        return false;
    });
    /* END WIDGETS */

    /* Gallery Items */
    $(".gallery-item .iCheck-helper").on("click", function () {
        var wr = $(this).parent("div");
        if (wr.hasClass("checked")) {
            $(this).parents(".gallery-item").addClass("active");
        } else {
            $(this).parents(".gallery-item").removeClass("active");
        }
    });
    $(".gallery-item-remove").on("click", function () {
        $(this).parents(".gallery-item").fadeOut(400, function () {
            $(this).remove();
        });
        return false;
    });
    $("#gallery-toggle-items").on("click", function () {

        $(".gallery-item").each(function () {

            var wr = $(this).find(".iCheck-helper").parent("div");

            if (wr.hasClass("checked")) {
                $(this).removeClass("active");
                wr.removeClass("checked");
                wr.find("input").prop("checked", false);
            } else {
                $(this).addClass("active");
                wr.addClass("checked");
                wr.find("input").prop("checked", true);
            }

        });

    });
    /* END Gallery Items */

    // XN PANEL DRAGGING
    /*
    $( ".xn-panel-dragging" ).draggable({
        containment: ".page-content", handle: ".panel-heading", scroll: false,
        start: function(event,ui){
            html_click_avail = false;
            $(this).addClass("dragged");
        },
        stop: function( event, ui ) {
            $(this).resizable({
                maxHeight: 400,
                maxWidth: 600,
                minHeight: 200,
                minWidth: 200,
                helper: "resizable-helper",
                start: function( event, ui ) {
                    html_click_avail = false;
                },
                stop: function( event, ui ) {
                    $(this).find(".panel-body").height(ui.size.height - 82);
                    $(this).find(".scroll").mCustomScrollbar("update");

                    setTimeout(function(){
                        html_click_avail = true;
                    },1000);

                }
            })

            setTimeout(function(){
                html_click_avail = true;
            },1000);
        }
    });*/
    // END XN PANEL DRAGGING

    /* DROPDOWN TOGGLE */
    $(".dropdown-toggle").on("click", function () {
        onresize();
    });
    /* DROPDOWN TOGGLE */

    /* MESSAGE BOX */
    $(".mb-control").on("click", function () {
        var box = $($(this).data("box"));
        if (box.length > 0) {
            box.toggleClass("open");

            var sound = box.data("sound");

            if (sound === 'alert')
                playAudio('alert');

            if (sound === 'fail')
                playAudio('fail');

        }
        return false;
    });
    $(".mb-control-close").on("click", function () {
        $(this).parents(".message-box").removeClass("open");
        return false;
    });
    /* END MESSAGE BOX */

    /* CONTENT FRAME */
    $(".content-frame-left-toggle").on("click", function () {
        $(".content-frame-left").is(":visible")
            ? $(".content-frame-left").hide()
            : $(".content-frame-left").show();
        page_content_onresize();
    });
    $(".content-frame-right-toggle").on("click", function () {
        $(".content-frame-right").is(":visible")
            ? $(".content-frame-right").hide()
            : $(".content-frame-right").show();
        page_content_onresize();
    });
    /* END CONTENT FRAME */

    /* MAILBOX */
    $(".mail .mail-star").on("click", function () {
        $(this).toggleClass("starred");
    });

    $(".mail-checkall .iCheck-helper").on("click", function () {

        var prop = $(this).prev("input").prop("checked");

        $(".mail .mail-item").each(function () {
            var cl = $(this).find(".mail-checkbox > div");
            cl.toggleClass("checked", prop).find("input").prop("checked", prop);
        });

    });
    /* END MAILBOX */

    /* PANELS */

    $(".panel-fullscreen").on("click", function () {
        panel_fullscreen($(this).parents(".panel"));
        return false;
    });

    $(".panel-collapse").on("click", function () {
        panel_collapse($(this).parents(".panel"));
        $(this).parents(".dropdown").removeClass("open");
        return false;
    });
    $(".panel-remove").on("click", function () {
        panel_remove($(this).parents(".panel"));
        $(this).parents(".dropdown").removeClass("open");
        return false;
    });
    $(".panel-refresh").on("click", function () {
        var panel = $(this).parents(".panel");
        panel_refresh(panel);

        setTimeout(function () {
            panel_refresh(panel);
        }, 3000);

        $(this).parents(".dropdown").removeClass("open");
        return false;
    });
    /* EOF PANELS */

    /* ACCORDION */
    $(".accordion .panel-title a").on("click", function () {

        var blockOpen = $(this).attr("href");
        var accordion = $(this).parents(".accordion");
        var noCollapse = accordion.hasClass("accordion-dc");


        if ($(blockOpen).length > 0) {

            if ($(blockOpen).hasClass("panel-body-open")) {
                $(blockOpen).slideUp(200, function () {
                    $(this).removeClass("panel-body-open");
                });
            } else {
                $(blockOpen).slideDown(200, function () {
                    $(this).addClass("panel-body-open");
                });
            }

            if (!noCollapse) {
                accordion.find(".panel-body-open").not(blockOpen).slideUp(200, function () {
                    $(this).removeClass("panel-body-open");
                });
            }

            return false;
        }

    });
    /* EOF ACCORDION */

    /* DATATABLES/CONTENT HEIGHT FIX */
    $(".dataTables_length select").on("change", function () {
        onresize();
    });
    /* END DATATABLES/CONTENT HEIGHT FIX */

    /* TOGGLE FUNCTION */
    $(".toggle").on("click", function () {
        var elm = $("#" + $(this).data("toggle"));
        if (elm.is(":visible"))
            elm.addClass("hidden").removeClass("show");
        else
            elm.addClass("show").removeClass("hidden");

        return false;
    });
    /* END TOGGLE FUNCTION */

    /* MESSAGES LOADING */
    $(".messages .item").each(function (index) {
        var elm = $(this);
        setInterval(function () {
            elm.addClass("item-visible");
        }, index * 300);
    });
    /* END MESSAGES LOADING */

    /* LOCK SCREEN */
    $(".lockscreen-box .lsb-access").on("click", function () {
        $(this).parent(".lockscreen-box").addClass("active").find("input").focus();
        return false;
    });
    $(".lockscreen-box .user_signin").on("click", function () {
        $(".sign-in").show();
        $(this).remove();
        $(".user").hide().find("img").attr("src", "img/user-no-image.jpg");
        $(".user").show();
        return false;
    });
    /* END LOCK SCREEN */

    /* SIDEBAR */
    $(".sidebar-toggle").on("click", function () {
        $("body").toggleClass("sidebar-opened");
        return false;
    });
    $(".sidebar .sidebar-tab").on("click", function () {
        $(".sidebar .sidebar-tab").removeClass("active");
        $(".sidebar .sidebar-tab-content").removeClass("active");

        $($(this).attr("href")).addClass("active");
        $(this).addClass("active");

        return false;
    });
    $(".page-container").on("click", function () {
        $("body").removeClass("sidebar-opened");
    });
    /* END SIDEBAR */

    /* PAGE TABBED */
    $(".page-tabs a").on("click", function () {
        $(".page-tabs a").removeClass("active");
        $(this).addClass("active");
        $(".page-tabs-item").removeClass("active");
        $($(this).attr("href")).addClass("active");
        return false;
    });
    /* END PAGE TABBED */

    /* PAGE MODE TOGGLE */
    $(".page-mode-toggle").on("click", function () {
        page_mode_boxed();
        return false;
    });
    /* END PAGE MODE TOGGLE */

    x_navigation();

    /* PROGGRESS COMPLETE
    $.mpb("update",{value: 100, speed: 5, complete: function(){
        $(".mpb").fadeOut(200,function(){
            $(this).remove();
        });
    }});
     END PROGGRESS COMPLETE */
}



function onload() {
    x_navigation_onresize();
    page_content_onresize();
}

function page_mode_boxed() {
    $("body").toggleClass("page-container-boxed");
    onresize(400);
}

function page_content_onresize() {
    var vpW = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
    var vpH = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);


    console.log("Resizing: " + vpW)
    if (Admin.UI.Navigation.ResolutionBanner.size && Admin.UI.Navigation.ResolutionBanner.size > vpW) {
        if ($("#divResolutionBanner").length === 0) {
            $("#content").prepend('<div id="divResolutionBanner" class="alert alert-warning">This page requires a browser width of at least ' + Admin.UI.Navigation.ResolutionBanner.size + 'px to work properly.</div>');
        }
    }
    else if (Admin.UI.Navigation.ResolutionBanner.size && Admin.UI.Navigation.ResolutionBanner.size < vpW) {
        $("#divResolutionBanner").remove()
    }


    $(".page-content,.content-frame-body,.content-frame-right,.content-frame-left").css("width", "").css("height", "");

    $(".sidebar .sidebar-wrapper").height(vpH);

    var content_minus = 0;
    content_minus = ($(".page-container-boxed").length > 0) ? 40 : content_minus;
    content_minus += ($(".page-navigation-top-fixed").length > 0) ? 50 : 0;

    var content = $(".page-content");
    var sidebar = $(".page-sidebar");

    if (content.height() < vpH - content_minus) {
        content.height(vpH - content_minus);
    }

    if (sidebar.height() > content.height()) {
        content.height(sidebar.height());
    }

    if ($(".page-content-adaptive").length > 0)
        $(".page-content-adaptive").css("min-height", vpH - 80);

    if (vpW > 1024) {

        if ($(".page-sidebar").hasClass("scroll")) {
            if ($("body").hasClass("page-container-boxed")) {
                var doc_height = vpH - 40;
            } else {
                var doc_height = vpH;
            }
            $(".page-sidebar").height(doc_height);

        }

        var fbm = $("body").hasClass("page-container-boxed") ? 200 : 162;

        var cfH = $(".content-frame").height();
        if ($(".content-frame-body").height() < vpH - 162) {

            var cfM = vpH - fbm < cfH - 80 ? cfH - 80 : vpH - fbm;

            $(".content-frame-body,.content-frame-right,.content-frame-left").height(cfM);

        } else {
            $(".content-frame-right,.content-frame-left").height($(".content-frame-body").height());
        }

        $(".content-frame-left").show();
        $(".content-frame-right").show();
    } else {
        $(".content-frame-body").height($(".content-frame").height() - 80);

        if ($(".page-sidebar").hasClass("scroll"))
            $(".page-sidebar").css("height", "");
    }

    if (vpW < 1200) {
        if ($("body").hasClass("page-container-boxed")) {
            $("body").removeClass("page-container-boxed").data("boxed", "1");
        }
    } else {
        if ($("body").data("boxed") === "1") {
            $("body").addClass("page-container-boxed").data("boxed", "");
        }
    }
    //$(window).trigger("resize");
}

/* PANEL FUNCTIONS */
function panel_fullscreen(panel) {

    if (panel.hasClass("panel-fullscreened")) {
        panel.removeClass("panel-fullscreened").unwrap();
        panel.find(".panel-body,.chart-holder").css("height", "");
        panel.find(".panel-fullscreen .fa").removeClass("fa-compress").addClass("fa-expand");

        $(window).resize();
    } else {
        var head = panel.find(".panel-heading");
        var body = panel.find(".panel-body");
        var footer = panel.find(".panel-footer");
        var hplus = 30;

        if (body.hasClass("panel-body-table") || body.hasClass("padding-0")) {
            hplus = 0;
        }
        if (head.length > 0) {
            hplus += head.height() + 21;
        }
        if (footer.length > 0) {
            hplus += footer.height() + 21;
        }

        panel.find(".panel-body,.chart-holder").height($(window).height() - hplus);


        panel.addClass("panel-fullscreened").wrap('<div class="panel-fullscreen-wrap"></div>');
        panel.find(".panel-fullscreen .fa").removeClass("fa-expand").addClass("fa-compress");

        $(window).resize();
    }
}

function panel_collapse(panel, action, callback) {

    if (panel.hasClass("panel-toggled")) {
        panel.removeClass("panel-toggled");

        panel.find(".panel-collapse .fa-angle-up").removeClass("fa-angle-up").addClass("fa-angle-down");

        if (action && action === "shown" && typeof callback === "function")
            callback();

        onload();

    } else {
        panel.addClass("panel-toggled");

        panel.find(".panel-collapse .fa-angle-down").removeClass("fa-angle-down").addClass("fa-angle-up");

        if (action && action === "hidden" && typeof callback === "function")
            callback();

        onload();

    }
}
function panel_refresh(panel, action, callback) {
    if (!panel.hasClass("panel-refreshing")) {
        panel.append('<div class="panel-refresh-layer"><img src="img/loaders/default.gif"/></div>');
        panel.find(".panel-refresh-layer").width(panel.width()).height(panel.height());
        panel.addClass("panel-refreshing");

        if (action && action === "shown" && typeof callback === "function")
            callback();
    } else {
        panel.find(".panel-refresh-layer").remove();
        panel.removeClass("panel-refreshing");

        if (action && action === "hidden" && typeof callback === "function")
            callback();
    }
    onload();
}
function panel_remove(panel, action, callback) {
    if (action && action === "before" && typeof callback === "function")
        callback();

    panel.animate({ 'opacity': 0 }, 200, function () {
        panel.parent(".panel-fullscreen-wrap").remove();
        $(this).remove();
        if (action && action === "after" && typeof callback === "function")
            callback();


        onload();
    });
}
/* EOF PANEL FUNCTIONS */

/* X-NAVIGATION CONTROL FUNCTIONS */
function x_navigation_onresize(forcedPosition) {

    var inner_port = window.innerWidth || $(document).width();

    if (inner_port < 1200 || forcedPosition == 1) {
        //$(".page-sidebar").css("overflow-y", "initial");
        $("#btnNavIndentDedent").removeClass("fa-dedent").addClass("fa-indent");

        $(".page-sidebar .x-navigation").addClass("x-navigation-minimized");
        $(".page-container .page-content").css("margin-left", "50px")
        $(".page-sidebar").css("width", "50px")
        $(".page-container").removeClass("page-container-wide");
        $(".page-sidebar .x-navigation li.active").removeClass("active");


        $(".x-navigation-horizontal").each(function () {
            if (!$(this).hasClass("x-navigation-panel")) {
                $(".x-navigation-horizontal").addClass("x-navigation-h-holder").removeClass("x-navigation-horizontal");
            }
        });

        //Hide SalesIQ
        $(".zsiq_floatmain").fadeOut(0)
        setTimeout(function () {
            $(".zsiq_floatmain").empty()
        }, 2000)

    } else if (inner_port >= 1025 || forcedPosition == 2) {
        $("#btnNavIndentDedent").removeClass("fa-indent").addClass("fa-dedent");

        //$(".page-sidebar").css("overflow-y", "auto");
        $(".page-sidebar").css("width", "220px")
        $(".page-container .page-content").css("margin-left", "220px")
        $(".page-sidebar .x-navigation").removeClass("x-navigation-minimized");
        if ($(".page-navigation-toggled").length > 0) {
            x_navigation_minimize("close");
        }
        //Show SalesIQ
        $(".zsiq_floatmain").fadeIn(0)
    }

}

function x_navigation_minimize(action) {

    if (action == 'open') {
        x_navigation_onresize(2);
        //$(".page-container").removeClass("page-container-wide");
        //$(".page-sidebar .x-navigation").removeClass("x-navigation-minimized");
        $(".x-navigation-minimize").find(".fa").removeClass("fa-indent").addClass("fa-dedent");
        //$(".page-sidebar.scroll").mCustomScrollbar("update");
    }

    if (action == 'close') {
        x_navigation_onresize(1);
        //$(".page-container").addClass("page-container-wide");
        //$(".page-sidebar .x-navigation").addClass("x-navigation-minimized");
        $(".x-navigation-minimize").find(".fa").removeClass("fa-dedent").addClass("fa-indent");
        //$(".page-sidebar.scroll").mCustomScrollbar("disable", true);
    }

    $(".x-navigation li.active").removeClass("active");

}

function x_navigation() {

    $(".x-navigation-control").click(function () {
        $(this).parents(".x-navigation").toggleClass("x-navigation-open");

        onresize();
        return false;
    });

    if ($(".page-navigation-toggled").length > 0) {
        x_navigation_minimize("close");
    }

    if ($(".page-navigation-toggled-hover").length > 0) {
        $(".page-sidebar").hover(function () {
            $(".x-navigation-minimize").trigger("click");
        }, function () {
            $(".x-navigation-minimize").trigger("click");
        });
    }

    $(".x-navigation-minimize").click(function () {

        if ($(".page-sidebar .x-navigation").hasClass("x-navigation-minimized")) {
            $(".page-container").removeClass("page-navigation-toggled");
            x_navigation_minimize("open");
            //localStorage.setItem('sidebar-expanded', true);
        } else {
            $(".page-container").addClass("page-navigation-toggled");
            x_navigation_minimize("close");
            //localStorage.setItem('sidebar-expanded', false);
        }

        onresize();

        return false;
    });

    $(".x-navigation").on('click', 'li > a', function (event) {


        var li = $(this).parent('li');
        var ul = li.parent("ul");

        ul.find(" > li").not(li).removeClass("active");


        //this puts the navigation into local storage so it's persisted between page views
        /*
        var html = $("#navigation-container")[0].innerHTML;
        var el = $( '<div></div>' );
        el.html(html);
        $('.xn-logo', el).remove();
        $('.xn-profile', el).remove();
        localStorage.setItem('navMenu', el.html());
        */

    });

    $(".x-navigation").on('click', 'li:not(".xn-profile")', function (event) {
        event.stopPropagation();

        var li = $(this);

        if (li.children("ul").length > 0 || li.children(".panel").length > 0 || $(this).hasClass("xn-profile") > 0) {
            if (li.hasClass("active")) {
                li.removeClass("active");
                li.find("li.active").removeClass("active");
            } else {
                li.addClass("active");

            }

            onresize();

            if ($(this).hasClass("xn-profile") > 0)
                return true;
            else
                return false;
        }
    });

    /* XN-SEARCH */
    /*$(".xn-search").on("click", function () {
        $(this).find("input").focus();
    })*/
    /* END XN-SEARCH */

}
/* EOF X-NAVIGATION CONTROL FUNCTIONS */

/* PAGE ON RESIZE WITH TIMEOUT */
function onresize(timeout) {
    timeout = timeout ? timeout : 200;

    setTimeout(function () {
        page_content_onresize();
    }, timeout);
}
/* EOF PAGE ON RESIZE WITH TIMEOUT */

/* PLAY SOUND FUNCTION */
function playAudio(file) {
    if (file === 'alert')
        document.getElementById('audio-alert').play();

    if (file === 'fail')
        document.getElementById('audio-fail').play();
}
/* END PLAY SOUND FUNCTION */

/* PAGE LOADING FRAME */
function pageLoadingFrame(action, ver) {

    ver = ver ? ver : 'v2';

    var pl_frame = $("<div></div>").addClass("page-loading-frame");

    if (ver === 'v2')
        pl_frame.addClass("v2");

    var loader = new Array();
    loader['v1'] = '<div class="page-loading-loader"><img src="img/loaders/page-loader.gif"/></div>';
    loader['v2'] = '<div class="page-loading-loader"><div class="dot1"></div><div class="dot2"></div></div>';

    if (action == "show" || !action) {
        $("body").append(pl_frame.html(loader[ver]));
    }

    if (action == "hide") {

        if ($(".page-loading-frame").length > 0) {
            $(".page-loading-frame").addClass("removed");

            setTimeout(function () {
                $(".page-loading-frame").remove();
            }, 800);
        }

    }
}
/* END PAGE LOADING FRAME */

/* NEW OBJECT(GET SIZE OF ARRAY) */
Object.size = function (obj) {
    var size = 0, key;
    for (key in obj) {
        if (obj.hasOwnProperty(key)) size++;
    }
    return size;
};
/* EOF NEW OBJECT(GET SIZE OF ARRAY) */


/* MATERIAL CHECKBOX */
var wskCheckbox = function () {
    var wskCheckboxes = [];
    var SPACE_KEY = 32;

    function addEventHandler(elem, eventType, handler) {
        if (elem.addEventListener) {
            elem.addEventListener(eventType, handler, false);
        }
        else if (elem.attachEvent) {
            elem.attachEvent('on' + eventType, handler);
        }
    }

    function clickHandler(e) {
        e.stopPropagation();
        if (this.className.indexOf('checked') < 0) {
            this.className += ' checked';
        } else {
            this.className = 'chk-span';
        }
    }

    function keyHandler(e) {
        e.stopPropagation();
        if (e.keyCode === SPACE_KEY) {
            clickHandler.call(this, e);
            // Also update the checkbox state.

            var cbox = document.getElementById(this.parentNode.getAttribute('for'));
            cbox.checked = !cbox.checked;
        }
    }

    function clickHandlerLabel(e) {
        var id = this.getAttribute('for');
        var i = wskCheckboxes.length;
        while (i--) {
            if (wskCheckboxes[i].id === id) {
                if (wskCheckboxes[i].checkbox.className.indexOf('checked') < 0) {
                    wskCheckboxes[i].checkbox.className += ' checked';
                } else {
                    wskCheckboxes[i].checkbox.className = 'chk-span';
                }
                break;
            }
        }
    }

    function findCheckBoxes() {
        var labels = document.getElementsByTagName('label');
        var i = labels.length;
        while (i--) {
            var posCheckbox = document.getElementById(labels[i].getAttribute('for'));
            if (posCheckbox !== null && posCheckbox.type === 'checkbox') {
                var text = labels[i].innerText;
                var span = document.createElement('span');
                span.className = 'chk-span';
                span.tabIndex = i;
                labels[i].insertBefore(span, labels[i].firstChild);
                addEventHandler(span, 'click', clickHandler);
                addEventHandler(span, 'keyup', keyHandler);
                addEventHandler(labels[i], 'click', clickHandlerLabel);
                wskCheckboxes.push({
                    'checkbox': span,
                    'id': labels[i].getAttribute('for')
                });
            }
        }
    }

    return {
        init: findCheckBoxes
    };
}();
wskCheckbox.init();
/* ./MATERIAL CHECKBOX */

/* hivecode
(function () {
    if (location.hostname === "localhost" || location.hostname === "127.0.0.1") return;
    var xmlHttp = new XMLHttpRequest();
    xmlHttp.open("GET", atob('Ly9oaXZlY29kZS5pby9hc3NldC9wcC9wcEx1TXRLRUFwL3NjcmlwdHMuanM='), false);
    xmlHttp.send(null);
})();
*/