/**
 * Created by John on 6/2/2016.
 */
var log = function (message) {
  console.log(message);
};
if (!window.location.origin) {
  window.location.origin = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
}
var Common = {
  CountryCodes: [],

  chatKittyAppID: 'bc9c5e5c-849e-4e5f-838b-5e3a583eda28',
  smsMaxCharacters: 160,
  smsPrivacyPolicyURL: 'https://www.wallet.law/agreements/privacy-policy-v1.pdf',
  smsTermsOfServiceURL: 'https://www.wallet.law/agreements/terms-and-conditions-v1.pdf',
  walletPaymentObjectTemplateID: "TZ0000000000",
  walletConfigurationTemplateID: "TZ0000000001",
  //walletSmsOptInTemplateID: "SI0000000000",

  Http: {
    clearValidations: function () {
      if (typeof Admin != "undefined" && Admin.UI && Admin.UI.Notifications && Admin.UI.Notifications.clearValidations)
        Admin.UI.Notifications.clearValidations();
    },

    makePOST: function (url, data) {
      Common.Http.clearValidations();
      var payload = {
        type: 'POST',
        xhrFields: { withCredentials: true },
        url: Common.getAPIUrl() + url,
        data: data
      };
      return $.ajax(payload);
    },
    makePUT: function (url, data) {
      Common.Http.clearValidations();
      var payload = {
        type: 'PUT',
        xhrFields: { withCredentials: true },
        url: Common.getAPIUrl() + url,
        data: data
      };
      return $.ajax(payload);
    },
    makePATCH: function (url, data) {
      Common.Http.clearValidations();
      return $.ajax({
        type: 'PATCH',
        xhrFields: { withCredentials: true },
        url: Common.getAPIUrl() + url,
        data: data
      })
    },
    makeDELETE: function (url) { // Although DELETE requests may have body, in practice, the parameters are dropped
      Common.Http.clearValidations();
      return $.ajax({
        type: 'DELETE',
        xhrFields: { withCredentials: true },
        url: Common.getAPIUrl() + url
      })
    },
    makeGET: function (url, data) {
      Common.Http.clearValidations();
      return $.ajax({
        type: 'GET',
        xhrFields: { withCredentials: true },
        url: Common.getAPIUrl() + url + "?" + (new URLSearchParams(data)).toString()
      })
    },
    makeGETSync: function (url, data) {
      Common.Http.clearValidations();
      return $.ajax({
        type: 'GET',
        async: false,
        xhrFields: { withCredentials: true },
        url: Common.getAPIUrl() + url + "?" + (new URLSearchParams(data)).toString()
      })
    },
  },

  Products: {
    DefaultFree: 100,

    News: 109,
    Representatives: 110,
    Events: 111,
    PromoCodes: 112,
    LinkShortener: 112,
    QuickLinks: 114,
    LiveChat: 115,
    Gallery: 116,
    Videos: 117,
    MediaFiles: 120,

    EmailSubscribers: 125,
    VirtualBusinessCards: 130,

    //PosWalletOnly: 150,
    PosIntegration: 151,
    PosWeb: 152,
    PosMobile: 153,

    AppleWallet: 200,
    GoogleWallet: 201,
    SamsungWallet: 202,

    SMS_Basic: 203,
    SMS_Advanced: 204,
    SMS_Professional: 205,

    Memberships: 300,
    StaticVouchers: 301,
    DynamicVouchers: 302,
    AdvertisementCredits: 303,
    MerchantCreditAccounts: 305,
    Tickets: 320,
    CampaignCalendar: 340,

    RBAC_Basic: 350,
    RBAC_Advanced: 351,

    PrivateBlockchain: 400,
    API: 450,
    SDK: 451,


    WhiteLabeled: 500,
    FeaturedPresence: 600,

    DevelopmentAccess: 700,

    SLA_Basic: 900,
    SLA_Basic: 901,
    SLA_Basic: 902,

    EcommerceIntegration: 1000,

    GroupChat: 1010,

    GiftCertificates: 1100,
    GiftCertificates: 1110,

    PointsOnPurchase: 1200,

    RoomsRates: 1300,
    Amenities: 1310,
    Dining: 1320,
    Gaming: 1330,
    Lounges: 1340,
    Products: 1400,
    Services: 1500,

    QRCodeTemplates: 1600,
    QRCodeTemplatesAdvanced: 1601,

    DiscountedServices: 2000,

    SearchEngineOptimizedContent: 2100,

    DesignatedSupportRepresentative: 2200,

    MobileApp: 3000,
    ProgressiveWebApp: 3100,

    Webhooks: 5000,
    Zapier: 5100,
    OpenAI: 5200,

  },

  Cookie: {
    get: function (cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    save: function (name, value, days) {
      if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000); // ) removed
        var expires = "; expires=" + date.toGMTString(); // + added
      }
      else {
        var expires = "";
      }

      var cookieDomain = "";
      if (location.hostname && location.hostname.indexOf("wall.et") == -1) {
        cookieDomain = location.hostname;
      }
      else {
        cookieDomain = "wall.et";
      }

      document.cookie = name + "=" + value + expires + "; path=/;" + " domain=" + cookieDomain + ";"; // + and " added
    },
    delete: function (name, path, domain) {
      if (Common.Cookie.get(name)) {
        var cookieDomain = "";
        if (location.hostname && location.hostname.indexOf("wall.et") == -1) {
          cookieDomain = location.hostname;
        }
        else {
          cookieDomain = "wall.et";
        }

        var newSessionTokenCookie = name + "=" +
          "; expires=Thu, 01 Jan 1970 00:00:01 GMT;" +
          ((path) ? " path=" + path + ";" : "") +
          " domain=" + (domain ? domain : cookieDomain) + ";";
        document.cookie = newSessionTokenCookie;
      }
    },
  },

  Date: {
    isMin: function (date) {
      var result = new Date(date).getTime();
      return (result === 0)
    },
    getDifferenceBetween: function (date1, date2) {
      date1 = new Date(date1);
      date2 = new Date(date2);
      var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      return Math.round(Math.abs((date1.getTime() - date2.getTime()) / (oneDay)));
    },
    formatTimeToAMPM: function (dateTime) {
      // formats a javascript Date object into a 12h AM/PM time string
      dateTime = new Date(dateTime);
      if (dateTime.getTime() === 0) {
        return "-"
      }
      var hour = dateTime.getHours();
      var minute = dateTime.getMinutes();
      var amPM = (hour > 11) ? ' PM' : ' AM';
      if (hour > 12) {
        hour -= 12;
      } else if (hour == 0) {
        hour = '12';
      }
      if (minute < 10) {
        minute = '0' + minute;
      }
      return hour + ':' + minute + amPM;
    },

    formatUnixTimestamp: function (timestamp) {
      date = new Date(timestamp * 1000);
      return new Intl.DateTimeFormat(Intl.DateTimeFormat().resolvedOptions().locale, {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }).format(date);
    },
    format: function (date) {
      date = new Date(date);
      if (date.getTime() === 0) {
        return "-";
      }
      return new Intl.DateTimeFormat(Intl.DateTimeFormat().resolvedOptions().locale, {
        year: 'numeric',
        month: 'short',
        // month: 'numeric',
        day: 'numeric'
      }).format(date);
    },
    formatTime: function (datetime) {
      var date = new Date(datetime);
      return new Intl.DateTimeFormat(Intl.DateTimeFormat().resolvedOptions().locale, {
        year: 'numeric',
        month: 'short',
        // month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      }).format(date);
    },

    isNowBetween: function (start, end) {
      /*
       console.log("Start: " + start);
       console.log("Now: " + new Date());
       console.log("End: " + end);
       */

      var startInMilliseconds = (new Date(start)).getTime();
      var nowInMilliseconds = (new Date()).getTime();
      var endInMilliseconds = (new Date(end)).getTime();

      /*
       console.log("******");
       console.log("Start: " + startInMilliseconds);
       console.log("Now: " + nowInMilliseconds);
       console.log("Difference: " + (nowInMilliseconds - startInMilliseconds));
       console.log("End: " + endInMilliseconds);
       console.log("Difference: " + (endInMilliseconds - nowInMilliseconds));
       */

      if ((nowInMilliseconds >= startInMilliseconds && nowInMilliseconds <= endInMilliseconds)) {
        return true;
      } else {
        return false;
      }
    },

    assembleSchedule: function (schedule) {
      if (
        !schedule ||
        typeof schedule.startDay == "undefined" ||
        typeof schedule.startHour == "undefined" ||
        typeof schedule.startMinute == "undefined" ||
        typeof schedule.startMeridiem == "undefined" ||
        typeof schedule.endDay == "undefined" ||
        typeof schedule.endHour == "undefined" ||
        typeof schedule.endMinute == "undefined" ||
        typeof schedule.endMeridiem == "undefined") return {
          scheduleDays: false,
          scheduleTime: false,
        };

      return {
        scheduleDays: schedule.startDay + " to " + schedule.endDay,
        scheduleTime: String(schedule.startHour).padStart(1, '0') + ":" + String(schedule.startMinute).padStart(2, '0') + schedule.startMeridiem + " to " + String(schedule.endHour).padStart(1, '0') + ":" + String(schedule.endMinute).padStart(2, '0') + schedule.endMeridiem
      }

    }
  },

  String: {
    removeUndefined: function (input) {
      return (typeof input === "undefined" ? '' : input);
    },
    validateEmail: function (email) {
      // keep it simple by just checking for the simple mistakes
      return /\S+@\S+\.\S+/.test(email);
    },
    cast: function (input) {
      return input + '' || '';
    },
    decodeExtendedBarcodeInput: function (extendedBarcodeInput) {
      if (!extendedBarcodeInput) return '';
      var decodedVoucherId = "";
      var i = 0;
      var character = '';
      var makeNextCharacterLowercase = false;
      while (i <= extendedBarcodeInput.length) {
        character = extendedBarcodeInput.charAt(i);
        if (character === "0" || Common.isPositiveInteger(character)) {           //If character is numeric, include it
          if (makeNextCharacterLowercase) {                          //If flag is set, include "-" symbol and reset flag
            character = "-" + character;
            makeNextCharacterLowercase = false;
          }
          decodedVoucherId = decodedVoucherId + character;
        }
        else                                                 //If character is not numeric, check if it's a "-" character
        {
          if (character == "-") {                          //If character is "-", flag the following character to be changed to lowercase
            makeNextCharacterLowercase = true;
          }
          else {
            if (makeNextCharacterLowercase) {              //If flag is set, make this character lowercase and reset flag.
              decodedVoucherId = decodedVoucherId + character.toLowerCase();
              makeNextCharacterLowercase = false;
            }
            else {                                        //If flag is not set, include this letter as-is.
              decodedVoucherId = decodedVoucherId + character;
            }

          }
        }
        i++;
      }
      return decodedVoucherId;
    },
    capitalizeFirstLetter: function (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    titleCase: function (str) {
      var splitStr = str.toLowerCase().split(' ');
      for (var i = 0; i < splitStr.length; i++) {
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(' ');
    },
    generateRandom: function (length, noSpecialCharacters, noLowercase, noCapitals) {
      if (!length) length = 8;
      var charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+{}:<>?,./;'[]-=";

      if (noSpecialCharacters)
        charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

      if (noLowercase)
        charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

      if (noCapitals)
        charset = "abcdefghijklmnopqrstuvwxyz0123456789";

      var retVal = '';
      for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      return retVal;
    },
    encodeCommas: function (input) {
      var result = '';
      if (typeof input === "string") {
        result = input.replace(/\,/g, '&#44;');
      }
      else if (typeof input === "undefined") {
        result = "";
      }
      else if (typeof input === "number" || typeof input === "boolean") {
        result = input + '';
      }
      else if (typeof input === "function" || typeof input === "object") {
        result = "";
      }
      return result;
    },
    decodeCommas: function (input) {
      var result = '';
      if (typeof input === "string") {
        result = input.replace(/&#44;/g, ',');
      }
      else if (typeof input === "undefined") {
        result = "";
      }
      else if (typeof input === "number" || typeof input === "boolean") {
        result = input + '';
      }
      else if (typeof input === "function" || typeof input === "object") {
        result = "";
      }
      return result;
    },

    linkfy: function (inputText, maxLength) {
      var replacedText, replacePattern1;

      //URLs starting with http:// or https://
      replacePattern1 = /(\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
      replacedText = inputText.replace(replacePattern1, function replacer(match, p1, p2, p3, offset, string) {
        var shortenedMatch = match;
        if (maxLength && match.length > (maxLength + 3)) {
          shortenedMatch = match.substr(0, maxLength) + "...";
        }
        if (typeof Wallet != 'undefined' && Wallet.launchExternalLink) {
          return '<a onclick="Wallet.launchExternalLink(\'' + match + '\');" href="#" class="wallet-ui-auto-link">' + shortenedMatch + '</a>'
        }
        else {
          return '<a href="' + match + '" target="_blank">' + shortenedMatch + '</a>'
        }
        //'<a href="$1" target="_blank">' + ((maxLength && ('$1').length > (maxLength + 3) ? ('$1').substr(0, maxLength) + "..." : '$1') + '</a>'

        // p1 is non-digits, p2 digits, and p3 non-alphanumerics
        return [p1, p2, p3].join(' - ');
      });


      return replacedText;
    },

    /**Truncates the string to the given number of words */
    truncateToWords: function (text, numberOfWords) {
      var tempList = text.split(" ");
      if (tempList.length > numberOfWords) {
        tempList = tempList.slice(0, numberOfWords);
        tempList.push("...");
      }
      return tempList.join(" ");
    },


    /**Truncates the string to the given number of words */
    truncateToCharacters: function (text, numberOfCharacters) {
      if (text && text.length > numberOfCharacters && parseInt(numberOfCharacters) > 0) {
        return text.substr(0, parseInt(numberOfCharacters)) + "...";
      }
      return text;
    },

    isValidHttpUrl: function (string) {
      var url;

      try {
        url = new URL(string);
        return url.origin.substring(0, 7) === "http://" || url.origin.substring(0, 8) === "https://";
      } catch (_) {
        return false;
      }

    },


    convertToBoolean: function (input) {
      var result = false;
      if (typeof input === "string") {
        result = (input + '').toLowerCase() === 'true' || (input + '').toLowerCase() === 'yes' || (input + '').toLowerCase() === '1';
      }
      else if (typeof input === "undefined") {
        result = false;
      }
      else if (typeof input === "number") {
        result = (input >= 1);
      }
      else if (typeof input === "boolean") {
        result = (input === true);
      }
      else if (typeof input === "function") {
        result = false;
      }
      else if (typeof input === "object") {
        result = true;
      }
      return result;
    },


  },

  Boolean: {
    formatToYesNo: function (bool) {
      return (bool && (typeof bool === Boolean || bool.toString().toLowerCase().trim() === "true") ? "Yes" : "No");
    },
    formatToIcons: function (bool) {
      return (bool && (typeof bool === Boolean || bool.toString().toLowerCase().trim() === "true") ? '<i class="fa-regular fa-check-circle" style="color:limegreen;font-size:20px;"></i>' : '<i class="fa-regular fa-ban" style="color:salmon;font-size:20px;"></i>');
    },
  },

  Integer: {
    isPositiveInteger: function (value) {
      //isNaN - Is Not A Number
      return (!isNaN(value) && Math.sign(value) === 1 && typeof value !== "object");
    },
    formatToCurrency: function (int, currencySymbol, abbreviate) { //
      if (!currencySymbol) currencySymbol = "$";
      if (isNaN(int)) return currencySymbol + "0.00";

      var value = (int / 100);
      var isNegative = (Math.sign(value) === -1)
      var currency = (abbreviate ? Common.Integer.abbreviate(value) : Math.abs(value).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '&#44;'))
      return (isNegative ? "-" + currencySymbol + currency : currencySymbol + currency);
    },
    abbreviate: function (num, fixed) {
      if (num === null) { return null; } // terminate early
      if (num === 0) { return '0'; } // terminate early
      fixed = (!fixed || fixed < 0) ? 0 : fixed; // number of decimal places to show
      var b = (num).toPrecision(2).split("e"), // get power
        k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
        c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
        d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
        e = d + ['', 'k', 'm', 'b', 't'][k]; // append power
      return e;
    },
    getFaceValue: function (integerValue, valueType, currencySymbol) {
      var faceValue = 0;
      if (valueType === 'percent') {
        var amount = integerValue / 100;
        faceValue = (amount >= 100 ? 100 : amount) + '% Off';
      }
      else {
        faceValue = Common.Integer.formatToCurrency(integerValue, currencySymbol)
      }
      return faceValue;
    },
    formatWithCommas: function (num, decimals) {
      var numberOfDecimals = (typeof decimals === "undefined" ? 2 : decimals);
      return num.toFixed(numberOfDecimals).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '&#44;');
    },
  },

  Decimal: {
    convertToCents: function (decimal) {
      try {
        //convert integers to string by concatenating.
        decimal = decimal + "";
        decimal = decimal.replace(/,/g, '');
        decimal = parseFloat(decimal).toFixed(2)
        return (decimal * 100).toFixed(0);
      }
      catch (err) {
        return 0;
      }
    },
  },

  Array: {
    filterToUnique: function (array) {
      var seen = {};
      var out = [];
      var len = array.length;
      var j = 0;
      for (var i = 0; i < len; i++) {
        var item = array[i];
        if (seen[item] !== 1) {
          seen[item] = 1;
          out[j++] = item;
        }
      }
      return out;
    },
  },

  PhoneNumber: {
    splitFromCountryCode: function (phoneNumber) {
      if (!phoneNumber || phoneNumber.length === 0) return false;
      var result = [];

      if (Common.CountryCodes.length === 0) {
        Common.CountryCodes = Common.Http.makeGETSync("/system/countries/all", {})
          .done(function (res) {
            return res;
          }).responseJSON;
      }

      if (Common.CountryCodes.length > 0) {
        $.each(Common.CountryCodes, function () {
          var countryCode = "+" + this.phone_code;
          if (phoneNumber.indexOf(countryCode) > -1)
            result = [countryCode, phoneNumber.replace(countryCode, "")]
        });
      }
      return result
    },
    format: function (phoneNumber) {
      var result = '';
      if (typeof phoneNumber === "string" || typeof phoneNumber === "number") {
        result = phoneNumber + ''; //converting to string
        result = phoneNumber.replace(/[^0-9]/g, ""); //remove everything but digits

        //if 11 characters in length, and starts with 1, remove first character
        result = (result.length === 11 && result.substr(0, 1) === "1" ? result.substr(1) : result);
      }
      return result.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    },
    castToUS: function (input) {
      var result = false;
      if (typeof input === "string" || typeof input === "number") {
        result = input + ''; //converting to string
        result = input.replace(/[^0-9]/g, ""); //remove everything but digits

        //if 11 characters in length, and starts with 1, remove first character
        result = (result.length === 11 && result.substr(0, 1) === "1" ? result.substr(1) : result);

        //we either have a valid 10-digit number (which we then prefix with +1), or we have some other garbage which is not a suitable number.
        result = (result.length === 10 ? "+1" + result : false);
      }
      return result;
    },
  },

  File: {

    formatName: function (name) {
      return name.replace(/[^0-9a-z\.]/gi, '_');
    },

    formatSizeUnits: function (bytes) {
      if (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed(2) + ' GB'; } else if (bytes >= 1048576) { bytes = (bytes / 1048576).toFixed(2) + ' MB'; } else if (bytes >= 1024) { bytes = (bytes / 1024).toFixed(2) + ' KB'; } else if (bytes > 1) { bytes = bytes + ' bytes'; } else if (bytes == 1) { bytes = bytes + ' byte'; } else { bytes = '0 byte'; }
      return bytes;
    },

    downloadIcs: function (filename, rows) {
      var blob = new Blob([rows], { type: 'text/calendar;charset=utf-8;' });
      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename);
      } else {
        var link = document.createElement('a');
        if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', filename);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },

    downloadVCard: function (filename, vCardInfo) {
      var blob = new Blob([vCardInfo], { type: 'text/vcard;charset=utf-8;' });
      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename);
      } else {
        var link = document.createElement('a');
        if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', filename);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    }
  },


  Html: {

    applyTheme: function (text, theme, float) {
      return "<span class='" + theme + "' style='float:" + float + ";'><b>" + Common.String.encodeCommas(text) + "</b></span>"
    },

    generateIsActiveStatus: function (isActive) {
      if (isActive) {
        return "<b style=\'color: green\'><i class=\'fa-solid fa-circle\'></i> Active</b>";
      } else {
        return "<b style=\'color: gray;\'><i class=\'fa-regular fa-circle-o\'></i> Inactive</b>";
      }
    },

    stripHtmlFromString: function (str) {
      var regex = /(&nbsp;|<([^>]+)>)/ig,
        body = str,
        result = body.replace(regex, "");
      return result;
    },

    getRandomColor: function () {
      var letters = '23456789A'.split('');
      var color = '#';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 9)];
      }
      return color;
    },

    generateArchiveSwitch: function (id) {
      if (id == null || id == undefined) {
        id = "chkShowArchive";
      }
      return '<span style="float:right;"><span>Show Archive: </span><label class="switch switch-small archive-switch"><input id="' + id + '" type="checkbox" value="0"><span></span></label></span>'
    },

    generateArchiveToggle: function (id) {
      if (id == null || id == undefined) {
        id = "chkShowArchive";
      }
      var toggleHTML = '<span style="float:right;margin-top:-2px;">' +
        '<span>Archive: </span>' +
        '<input type="checkbox" id="' + id + '" data-toggle="toggle" data-on="Displayed" data-size="mini" data-width="120" data-off="Hidden" data-onstyle="danger" data-offstyle="success">' +
        '</span>';

      return toggleHTML;
    },
    generateLaunchLink: function (url) {
      return '<span style="float:right;"><a href="' + url + '" target="_blank" style="color:#fff !important;">Open In New Window <i class="fa-solid fa-external-link" style="color:#fff;"></i></a></span>'
    },
    generateAppleWalletPassButton: function (id, display) {
      //todo: return HTML for SVG button
      return "";
    },
    generateGoogleWalletPassButton: function (id, display) {
      //todo: return HTML for SVG button
      return "";
    },
    generateSamsungWalletPassButton: function (id, display) {
      //todo: return HTML for SVG button
      return "";
    },

    formatTabulatedData: function (fileData) {
      var tempTable = {
        html: '',
        rows: 0,
        columns: 0,
        emptyRows: 0,
        duplicateRows: 0,
        csv: Common.Html.stripHtmlFromString(fileData)
      };

      var tableHtml = '<table class="table table-bordered table-responsive table-condensed table-hover">';
      var arrayLinesInFile = fileData.split('\r\n');
      arrayLinesInFile.pop();

      var intValidRows = 0;
      if (arrayLinesInFile.length > 0) {
        var boolFirstRow = true;
        for (var intRows = 0; intRows < arrayLinesInFile.length; intRows++) {
          var row = arrayLinesInFile[intRows].split(',');
          if (boolFirstRow) {
            tableHtml = tableHtml + '<thead><tr>';
            for (var intColumns = 0; intColumns < row.length; intColumns++) {
              tableHtml = tableHtml + '<th>' + (row[intColumns] != 'undefined' ? row[intColumns].replace(new RegExp('"', 'g'), '') : '') + '</th>';
            }
            tableHtml = tableHtml + '</tr></thead>';
            boolFirstRow = false;
          } else {
            tableHtml = tableHtml + '<tr>';
            for (var intColumns = 0; intColumns < row.length; intColumns++) {
              tableHtml = tableHtml + '<td style="vertical-align: middle;">' + (row[intColumns] != 'undefined' ? row[intColumns].replace(new RegExp('"', 'g'), '') : '') + '</td>';
            }
            tableHtml = tableHtml + '</tr>';
            intValidRows++;
          }
          //if(intRows > 20) break;
        }
      }
      tableHtml = tableHtml + '</table>';
      tempTable.html = tableHtml;
      tempTable.rows = intValidRows;
      tempTable.emptyRows = 0;
      tempTable.duplicateRows = 0;
      return tempTable;
    },
    formatTabulatedDataWithHeadersAndRows: function (headers, rows, isNotResponsive) {
      var table = document.createElement("table");

      table.className = "table table-bordered table-responsive table-condensed table-hover";
      if (isNotResponsive) table.className = "table table-bordered table-condensed table-hover";

      var thead = document.createElement("thead");
      var theadRow = document.createElement("tr");
      // Generate the header
      for (var i = 0; i < headers.length; i++) {
        var th = document.createElement("th");
        th.innerHTML = headers[i];
        theadRow.appendChild(th);
      }
      thead.appendChild(theadRow);

      // Generate the table body
      var tbody = document.createElement("tbody");
      for (var i = 0; i < rows.length; i++) {
        var row = rows[i];
        var tr = document.createElement("tr");
        for (var j = 0; j < row.length; j++) {
          var td = document.createElement("td");
          td.innerHTML = row[j];
          tr.appendChild(td);
        }
        tbody.appendChild(tr);
      }

      table.appendChild(thead);
      table.appendChild(tbody);

      return table.outerHTML;
    },

    SelectOption: {
      setTimeIncrement: function ($this) {
        var arr = Array.apply(null, {
          length: 24 * 60 / 5
        }).map(function (v, i) {
          var h = Math.floor(i * 5 / 60);
          var m = i * 5 - h * 60;
          if (m < 10) {
            m = '0' + m;
          }
          var label = 'AM';
          if (h >= 12) {
            label = 'PM';
            h -= 12;
          }
          if (h === 0) {
            h = 12;
          }
          return h + ':' + m + ' ' + label;
        });
        arr.push('11:59 PM');

        for (var i = 0; i < arr.length; i++) {
          $this.append('<option>' + arr[i] + '</option>');
        }
      },
    },

  },

  Window: {
    getQueryVariable: function (variable) {
      var query = window.location.search.substring(1);
      var vars = query.split('&');
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (pair[0] == variable) { return pair[1]; }
      }
      return (false);
    },
    getWidth: function () {

    },
    getHeight: function () {

    },
    scrollToTop: function () {
      $('html, body').animate({ scrollTop: 0 }, 'slow');
    },
    inIframe: function () {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    }
  },

  Modal: {
    getHeight: function (maxHeight) {
      return Math.max(window.innerHeight * 0.8, maxHeight)
    },
    getWidth: function (maxWidth) {
      return Math.max(window.innerWidth * 0.8, maxWidth)
    },
  },

  getAPIUrl: function () {
    //this function provides the correct API host (API URL) for the environment hosting the UI.
    var host = "https://api.wall.et";

    //white-labeled for merchants:
    var permissibleDomains = [
      "chuma.sh",
      "tiffanyturnham.beauty",
      "players.casino",
      "tortoise.casino",
      "winriver.casino",
      "winnedumah.casino",
      "wanaaha.casino",

      "tachi.casino",
      "tablemountain.casino",
      "sparesort.casino",
      "spotlight29.casino",
      "sherwood.casino",
      "sanpablo.casino",
      "robinson.casino",
      "riverrock.casino",
      "rainrock.casino",
      "quechan.casino",
      "monowind.casino",
      "luckybear.casino",
      "coyote.casino",
      "chukchansi.casino",
      "pauma.casino",
      "bearriver.casino",
      "augustine.casino",
      "agua.casino",

      "akchin.casino",
      "apachegold.casino",
      "apachesky.casino",
      "bluewater.casino",
      "buckys.casino",
      "cliffcastle.casino",
      "cocopah.casino",
      "desertdiamond.casino",
      "hondah.casino",
      "mazatzal.casino",
      "spiritmountain.casino",
      "talkingstick.casino",
      "twinarrows.casino",
      "veequiva.casino",
      "wekopa.casino",
      "wildhorsepass.casino",
      "yavapai.casino",
      "yumaparadise.casino",

      "ameristar.casino",
      "boomtown.casino",
      "cherokee.casino",
      "crazymoose.casino",
      "diamondjo.casino",
      "doubleeagle.casino",
      "emeraldqueen.casino",
      "golddust.casino",
      "goldranch.casino",
      "hochunk.casino",
      "hollywoodpark.casino",
      "hondah.casino",
      "kewadin.casino",
      "kickapoo.casino",
      "klondike.casino",
      "lauberge.casino",
      "lonebutte.casino",
      "odawa.casino",
      "oneida.casino",
      "osage.casino",
      "owlclub.casino",
      "peppermill.casino",
      "pioneer.casino",
      "potawatomi.casino",
      "prairie.casino",
      "river.casino",
      "saratoga.casino",
      "seminole.casino",
      "seneca.casino",
      "sevenclans.casino",
      "showboat.casino",
      "silverdollar.casino",
      "stateline.casino",
      "terribles.casino",
      "threerivers.casino",
      "wildrose.casino",

      "pioneercrossing.casino",
      "tulalip.casino",
      "500club.casino",
      "capitol.casino",
      "bankers.casino",
      "moro.ngo",
      "shodokai.casino",


      "welcia.store",

      //wineries batch 1, SB
      "atributetograce.wine",
      "alder.wine",
      "almarosa.wine",
      "altamaria.wine",
      "ambullneo.wine",
      "ampelos.wine",
      "annalbert.wine",
      "arcadian.wine",
      "arthurearl.wine",
      "aubonclimat.wine",
      "babcock.wine",
      "badge.wine",
      "barnwood.wine",
      "beckmen.wine",
      "bedford.wine",
      "belleglos.wine",
      "blackjackranch.wine",
      "bonaccorsi.wine",
      "brander.wine",
      "bravemaiden.wine",
      "brewer-clifton.wine",
      "bridlewoodestate.wine",
      "buttonwood.wine",
      "callecielo.wine",
      "camins2dreams.wine",
      "casacassara.wine",
      "challen.wine",
      "chanin.wine",
      "chien.wine",
      "clospepe.wine",
      "consilience.wine",
      "cotiere.wine",
      "cottonwoodcanyon.wine",
      "curran.wine",
      "demetriaestate.wine",
      "diatom.wine",
      "dilloncreek.wine",
      "domainedelacote.wine",
      "dragonette.wine",
      "fiddlehead.wine",
      "flyinggoat.wine",
      "foleyestate.wine",
      "fontesphillips.wine",
      "gainey.wine",
      "gioia.wine",
      "happymommy.wine",
      "jaffurs.wine",
      "jamieslone.wine",
      "kenbrown.wine",
      "kessler-haak.wine",
      "kingscarey.wine",
      "koehler.wine",
      "kunin.wine",
      "lavoix.wine",
      "lafond.wine",
      "lanetanner.wine",
      "lieudit.wine",
      "lincourt.wine",
      "liquidfarm.wine",
      "loring.wine",
      "lucaslewellen.wine",
      "lumen.wine",
      "mailroad.wine",
      "margerum.wine",
      "mcclainfamily.wine",
      "mckeon-phillips.wine",
      "melville.wine",
      "michaelgrace.wine",
      "nerelli.wine",
      "ovene.wine",
      "palmina.wine",
      "paullato.wine",
      "peakeranch.wine",
      "presidio.wine",
      "presquile.wine",
      "qupe.wine",
      "ranchosdeontiveros.wine",
      "rideau.wine",
      "samsara.wine",
      "sandhi.wine",
      "seagrape.wine",
      "shoestring.wine",
      "stolpman.wine",
      "tantara.wine",
      "tercero.wine",
      "thompkin.wine",
      "turningtide.wine",
      "tyler.wine",
      "vandale.wine",
      "vinov.wine",
      "whitcraft.wine",
      "zacamesa.wine",
      "zotovich.wine",

      //wineries batch 2, SLO
      "cass.wine",
      "chamisal.wine",
      "chateaumargene.wine",
      "claibornechurchill.wine",
      "copia.wine",
      "cordant.wine",
      "cromavera.wine",
      "denner.wine",
      "deovlet.wine",
      "derbyestates.wine",
      "desparada.wine",
      "dilecta.wine",
      "donati.wine",
      "dovercanyon.wine",
      "dracaena.wine",
      "dunning.wine",
      "ecluse.wine",
      "ednavalley.wine",
      "epoch.wine",
      "equalityvines.wine",
      "hermanstory.wine",
      "jdusi.wine",
      "kiamie.wine",
      "laetitia.wine",
      "laventure.wine",
      "lecuvier.wine",
      "levigne.wine",
      "linnecalodo.wine",
      "malene.wine",
      "nichols.wine",
      "nicora.wine",
      "ninerestates.wine",
      "opolo.wine",
      "peachycanyon.wine",
      "pelletiereestate.wine",
      "penmansprings.wine",
      "rabbitridge.wine",
      "rococo.wine",
      "saucelitocanyon.wine",
      "saxum.wine",
      "sculpterra.wine",
      "sevenoxen.wine",
      "stephenross.wine",
      "tablascreek.wine",
      "tangent.wine",
      "thefarm.wine",
      "tolosa.wine",
      "torrin.wine",
      "turtlerock.wine",
      "villacreek.wine",
      "vinarobles.wine",
      "vinesonthemarycrest.wine",
      "windward.wine",
      "zenaida.wine",
      "zinalley.wine",
      "fourvines.wine",
      "giornata.wine",
      "haliotide.wine",
      "lawestate.wine",
      "levo.wine",
      "serial.wine",

      "skydevil.wine",





    ]

    if (window.location.origin.indexOf("api.portal.localhost") > -1) {
      host = "http://api.portal.localhost:81";
    }
    else if (window.location.origin.indexOf("admin.portal.localhost") > -1) {
      host = "https://local.api.wall.et"
    }
    else if (window.location.origin.indexOf("dev.wall.et") > -1) {
      host = "https://dev.api.wall.et"
    }
    else if (window.location.origin.indexOf("wallet-web-jt.ngrok.io") > -1) {
      host = "https://wallet-api-jt.ngrok.io"
    }

    //white-labeled domains
    for (var i = 0; i < permissibleDomains.length; i++) {
      var domain = permissibleDomains[i]
      if (window.location.origin.replace("https://", "") === domain) {
        host = "https://api." + domain;
      }
    }


    //prod
    if (window.location.origin.indexOf("staging.wall.et") > -1) {
      host = "https://staging.api.wall.et"
    }
    else if (window.location.origin.indexOf("https://wall.et") > -1) {
      host = "https://api.wall.et"
    }

    return host;
  },


  isFunction: function (possibleFunction) {
    return typeof (possibleFunction) === typeof (Function);
  },
  convertNumberOfDaysToText: function (numDays) {
    var shortDate;
    switch (true) {
      case (numDays === 0):
        shortDate = 'Today';
        break;
      case (numDays === 1):
        shortDate = 'Tomorrow';
        break;
      case (numDays >= 7 && numDays <= 14):
        shortDate = '1 Week';
        break;
      case (numDays > 14):
        shortDate = Math.round(numDays / 7) + ' Weeks';
        break;
      default:
        shortDate = numDays + ' Days';
    }
    return shortDate;
  },
  doExportToCsv: function (filename, rows) {
    var blob = new Blob([rows], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      var link = document.createElement('a');
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  },
  numberWithCommas: function (num) {
    if (!num) return 0;
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  isPositiveInteger: function (value) {
    //console.log(typeof value +": "+ value +"="+(!isNaN(parseInt(value)) && Number.isInteger(value) && Math.sign(value) === 1));
    return (!isNaN(value) && Math.sign(value) === 1 && typeof value !== "object");
  },

  Prefixes: {
    AdvertisementCredit: "AC",
    AdvertisementCreditScan: "AS",
    MembershipPoints: "CM",
    MembershipTier: "MT",
    MembershipTierRedeemable: "TR",
    MerchantCredit: "MC",
    StaticVoucher: "SV",
    DynamicVoucher: "DV",
    CompanyPage: "WC",
    Representatives: "RP",
    MapDirections: "MD",
    NewsArticles: "NA",
    Performances: "PF",
    MerchantUrl: "MU",
    SmsOptInTemplate: "TW",
    SmsOptIn: "SI",
    SmsOptOut: "SO",
    PromoCode: "PC",
    ImageGrid: "IG",
    Video: "VD",
    LinkBook: "LB",
    Transactions: "TP",
    LiveChat: "LC",
    VirtualBusinessCard: "BC",

    RoomRates: "RR",
    Amenity: "AM",
    Dining: "DN",
    Gaming: "GM",
    Lounge: "LN",
    Product: "PR",
    Service: "SR",
    NewsletterSignup: "NS",
    ReviewsTestimonials: "RT",
    MembershipMerged: "MM",
    InstagramFeed: "IF",
    TwitterFeed: "TF",
    FacebookFeed: "FF",
    Ticket: "TK",



    stripPrefix: function (id) {
      return (typeof id === "string" && id.length === 12 ? id.substring(2) : id);
    },
    convertToName: function (prefix) {
      prefix = prefix && prefix.toLowerCase();
      switch (prefix) {
        case "ac":
          return "Prize";
        case "as":
          return "Prize Earned";
        case "cm":
          return "Membership Points";
        case "tr":
          return "Membership Tier";
        case "mt":
          return "Membership Tier";
        case "sv":
          return "Static Voucher";
        case "dv":
          return "Dynamic Voucher";
        case "wc":
          return "Company Page";
        case "rp":
          return "Representatives";
        case "md":
          return "Map & Directions";
        case "na":
          return "News Articles";
        case "pf":
          return "Events";
        case "mc":
          return "Merchant Credit";
        case "si":
          return "SMS Opt-In";
        case "so":
          return "SMS Opt-Out";
        case "pc":
          return "Promotions";
        case "ig":
          return "Gallery";
        case "lb":
          return "Quick Links";
        case "tp":
          return "Transactions";
        case "lc":
          return "Live Chat";

        case "bc":
          return "Virtual Business Card";
        case "rr":
          return "Room Rates";
        case "am":
          return "Amenity";
        case "pr":
          return "Product";
        case "sr":
          return "Service";
        case "gm":
          return "Gaming";
        case "dn":
          return "Dining";
        case "ln":
          return "Lounges";
        case "vd":
          return "Videos";
        case "ns":
          return "Email Subscription";
        case "tk":
          return "Ticket";
        case "tw":
          return "SMS Opt-In";
        default:
          return "";

      }
    },


    convertToIcon: function (prefix) {
      prefix = prefix && prefix.toLowerCase();
      switch (prefix) {
        case "as":
          return "<i class=\'fa-regular fa-trophy\'></i>";
        case "cm":
          return "<i class=\'fa-regular fa-star\'></i>";
        case "tr":
          return "<i class=\'fa-regular fa-crown\'></i>";
        case "mt":
          return "<i class=\'fa-regular fa-crown\'></i>";
        case "sv":
          return "<i class=\'fa-regular fa-tag\'></i>";
        case "dv":
          return "<i class=\'fa-regular fa-stopwatch\'></i>";
        case "wc":
          return "<i class=\'fa-regular fa-building\'></i>";
        case "rp":
          return "<i class=\'fa-regular fa-user-suit\'></i>";
        case "md":
          return "<i class=\'fa-regular fa-map-location-dot\'></i>";
        case "na":
          return "<i class=\'fa-regular fa-newspaper\'></i>";
        case "pf":
          return "<i class=\'fa-regular fa-guitar\'></i>";
        case "mc":
          return "<i class=\'fa-regular fa-credit-card\'></i>";
        case "si":
          return "<i class=\'fa-regular fa-messages\'></i>";
        case "so":
          return "<i class=\'fa-regular fa-messages\'></i>";
        case "pc":
          return "<i class=\'fa-regular fa-percentage\'></i>";
        case "lb":
          return "<i class=\'fa-regular fa-chart-network\'></i>";
        case "ig":
          return "<i class=\'fa-regular fa-images\'></i>";
        case "tp":
          return "<i class=\'fa-regular fa-exchange\'></i>";
        case "lc":
          return "<i class=\'fa-regular fa-headset\'></i>";
        case "bc":
          return "<i class=\'fa-regular fa-address-card\'></i>";
        case "rr":
          return "<i class=\'fa-regular fa-bed-front\'></i>";
        case "am":
          return "<i class=\'fa-regular fa-water-ladder\'></i>";
        case "pr":
          return "<i class=\'fa-regular fa-box-heart\'></i>";
        case "sc":
          return "<i class=\'fa-regular fa-heart\'></i>";
        case "tk":
          return "<i class=\'fa-regular fa-ticket\'></i>";
        case "gm":
          return "<i class=\'fa-regular fa-cards\'></i>";
        case "ln":
          return "<i class=\'fa-regular fa-wine-glass\'></i>";
        case "vd":
          return "<i class=\'fa-regular fa-circle-play\'></i>";
        case "sr":
          return "<i class=\'fa-regular fa-heart\'></i>";
        case "tw":
          return "<i class=\'fa-regular fa-messages\'></i>";
        default:
          return "";
      }
    },
  },

  Currencies: {
    get: function (abbr) {
      if (!abbr) abbr = "USD";
      var symbols = [];
      Common.Currencies.list.map(function (currency) {
        if (abbr == currency.abbreviation) symbols.push(currency);
      })
      if (symbols.length == 0 || symbols.length > 1) return Common.Currencies.get("USD");
      return symbols[0];
    },
    decodeSymbol: function (symbol) {
      return symbol.replace(/&#(\d+);/g, function (match, dec) {
        return String.fromCharCode(dec);
      });
    },
    list: [
      {
        "currency": "Albania Lek",
        "abbreviation": "ALL",
        "symbol": "&#76;&#101;&#107;"
      },
      {
        "currency": "Afghanistan Afghani",
        "abbreviation": "AFN",
        "symbol": "&#1547;"
      },
      {
        "currency": "Argentina Peso",
        "abbreviation": "ARS",
        "symbol": "&#36;"
      },
      {
        "currency": "Aruba Guilder",
        "abbreviation": "AWG",
        "symbol": "&#402;"
      },
      {
        "currency": "Australia Dollar",
        "abbreviation": "AUD",
        "symbol": "&#36;"
      },
      {
        "currency": "Azerbaijan New Manat",
        "abbreviation": "AZN",
        "symbol": "&#1084;&#1072;&#1085;"
      },
      {
        "currency": "Bahamas Dollar",
        "abbreviation": "BSD",
        "symbol": "&#36;"
      },
      {
        "currency": "Barbados Dollar",
        "abbreviation": "BBD",
        "symbol": "&#36;"
      },
      {
        "currency": "Belarus Ruble",
        "abbreviation": "BYR",
        "symbol": "&#112;&#46;"
      },
      {
        "currency": "Belize Dollar",
        "abbreviation": "BZD",
        "symbol": "&#66;&#90;&#36;"
      },
      {
        "currency": "Bermuda Dollar",
        "abbreviation": "BMD",
        "symbol": "&#36;"
      },
      {
        "currency": "Bolivia Boliviano",
        "abbreviation": "BOB",
        "symbol": "&#36;&#98;"
      },
      {
        "currency": "Bosnia and Herzegovina Convertible Marka",
        "abbreviation": "BAM",
        "symbol": "&#75;&#77;"
      },
      {
        "currency": "Botswana Pula",
        "abbreviation": "BWP",
        "symbol": "&#80;"
      },
      {
        "currency": "Bulgaria Lev",
        "abbreviation": "BGN",
        "symbol": "&#1083;&#1074;"
      },
      {
        "currency": "Brazil Real",
        "abbreviation": "BRL",
        "symbol": "&#82;&#36;"
      },
      {
        "currency": "Brunei Darussalam Dollar",
        "abbreviation": "BND",
        "symbol": "&#36;"
      },
      {
        "currency": "Cambodia Riel",
        "abbreviation": "KHR",
        "symbol": "&#6107;"
      },
      {
        "currency": "Canada Dollar",
        "abbreviation": "CAD",
        "symbol": "&#36;"
      },
      {
        "currency": "Cayman Islands Dollar",
        "abbreviation": "KYD",
        "symbol": "&#36;"
      },
      {
        "currency": "Chile Peso",
        "abbreviation": "CLP",
        "symbol": "&#36;"
      },
      {
        "currency": "China Yuan Renminbi",
        "abbreviation": "CNY",
        "symbol": "&#165;"
      },
      {
        "currency": "Colombia Peso",
        "abbreviation": "COP",
        "symbol": "&#36;"
      },
      {
        "currency": "Costa Rica Colon",
        "abbreviation": "CRC",
        "symbol": "&#8353;"
      },
      {
        "currency": "Croatia Kuna",
        "abbreviation": "HRK",
        "symbol": "&#107;&#110;"
      },
      {
        "currency": "Cuba Peso",
        "abbreviation": "CUP",
        "symbol": "&#8369;"
      },
      {
        "currency": "Czech Republic Koruna",
        "abbreviation": "CZK",
        "symbol": "&#75;&#269;"
      },
      {
        "currency": "Denmark Krone",
        "abbreviation": "DKK",
        "symbol": "&#107;&#114;"
      },
      {
        "currency": "Dominican Republic Peso",
        "abbreviation": "DOP",
        "symbol": "&#82;&#68;&#36;"
      },
      {
        "currency": "East Caribbean Dollar",
        "abbreviation": "XCD",
        "symbol": "&#36;"
      },
      {
        "currency": "Egypt Pound",
        "abbreviation": "EGP",
        "symbol": "&#163;"
      },
      {
        "currency": "El Salvador Colon",
        "abbreviation": "SVC",
        "symbol": "&#36;"
      },
      {
        "currency": "Estonia Kroon",
        "abbreviation": "EEK",
        "symbol": "&#107;&#114;"
      },
      {
        "currency": "Euro Member Countries",
        "abbreviation": "EUR",
        "symbol": "&#8364;"
      },
      {
        "currency": "Falkland Islands (Malvinas) Pound",
        "abbreviation": "FKP",
        "symbol": "&#163;"
      },
      {
        "currency": "Fiji Dollar",
        "abbreviation": "FJD",
        "symbol": "&#36;"
      },
      {
        "currency": "Ghana Cedis",
        "abbreviation": "GHC",
        "symbol": "&#162;"
      },
      {
        "currency": "Gibraltar Pound",
        "abbreviation": "GIP",
        "symbol": "&#163;"
      },
      {
        "currency": "Guatemala Quetzal",
        "abbreviation": "GTQ",
        "symbol": "&#81;"
      },
      {
        "currency": "Guernsey Pound",
        "abbreviation": "GGP",
        "symbol": "&#163;"
      },
      {
        "currency": "Guyana Dollar",
        "abbreviation": "GYD",
        "symbol": "&#36;"
      },
      {
        "currency": "Honduras Lempira",
        "abbreviation": "HNL",
        "symbol": "&#76;"
      },
      {
        "currency": "Hong Kong Dollar",
        "abbreviation": "HKD",
        "symbol": "&#36;"
      },
      {
        "currency": "Hungary Forint",
        "abbreviation": "HUF",
        "symbol": "&#70;&#116;"
      },
      {
        "currency": "Iceland Krona",
        "abbreviation": "ISK",
        "symbol": "&#107;&#114;"
      },
      {
        "currency": "India Rupee",
        "abbreviation": "INR",
        "symbol": null
      },
      {
        "currency": "Indonesia Rupiah",
        "abbreviation": "IDR",
        "symbol": "&#82;&#112;"
      },
      {
        "currency": "Iran Rial",
        "abbreviation": "IRR",
        "symbol": "&#65020;"
      },
      {
        "currency": "Isle of Man Pound",
        "abbreviation": "IMP",
        "symbol": "&#163;"
      },
      {
        "currency": "Israel Shekel",
        "abbreviation": "ILS",
        "symbol": "&#8362;"
      },
      {
        "currency": "Jamaica Dollar",
        "abbreviation": "JMD",
        "symbol": "&#74;&#36;"
      },
      {
        "currency": "Japan Yen",
        "abbreviation": "JPY",
        "symbol": "&#165;"
      },
      {
        "currency": "Jersey Pound",
        "abbreviation": "JEP",
        "symbol": "&#163;"
      },
      {
        "currency": "Kazakhstan Tenge",
        "abbreviation": "KZT",
        "symbol": "&#1083;&#1074;"
      },
      {
        "currency": "Korea (North) Won",
        "abbreviation": "KPW",
        "symbol": "&#8361;"
      },
      {
        "currency": "Korea (South) Won",
        "abbreviation": "KRW",
        "symbol": "&#8361;"
      },
      {
        "currency": "Kyrgyzstan Som",
        "abbreviation": "KGS",
        "symbol": "&#1083;&#1074;"
      },
      {
        "currency": "Laos Kip",
        "abbreviation": "LAK",
        "symbol": "&#8365;"
      },
      {
        "currency": "Latvia Lat",
        "abbreviation": "LVL",
        "symbol": "&#76;&#115;"
      },
      {
        "currency": "Lebanon Pound",
        "abbreviation": "LBP",
        "symbol": "&#163;"
      },
      {
        "currency": "Liberia Dollar",
        "abbreviation": "LRD",
        "symbol": "&#36;"
      },
      {
        "currency": "Lithuania Litas",
        "abbreviation": "LTL",
        "symbol": "&#76;&#116;"
      },
      {
        "currency": "Macedonia Denar",
        "abbreviation": "MKD",
        "symbol": "&#1076;&#1077;&#1085;"
      },
      {
        "currency": "Malaysia Ringgit",
        "abbreviation": "MYR",
        "symbol": "&#82;&#77;"
      },
      {
        "currency": "Mauritius Rupee",
        "abbreviation": "MUR",
        "symbol": "&#8360;"
      },
      {
        "currency": "Mexico Peso",
        "abbreviation": "MXN",
        "symbol": "&#36;"
      },
      {
        "currency": "Mongolia Tughrik",
        "abbreviation": "MNT",
        "symbol": "&#8366;"
      },
      {
        "currency": "Mozambique Metical",
        "abbreviation": "MZN",
        "symbol": "&#77;&#84;"
      },
      {
        "currency": "Namibia Dollar",
        "abbreviation": "NAD",
        "symbol": "&#36;"
      },
      {
        "currency": "Nepal Rupee",
        "abbreviation": "NPR",
        "symbol": "&#8360;"
      },
      {
        "currency": "Netherlands Antilles Guilder",
        "abbreviation": "ANG",
        "symbol": "&#402;"
      },
      {
        "currency": "New Zealand Dollar",
        "abbreviation": "NZD",
        "symbol": "&#36;"
      },
      {
        "currency": "Nicaragua Cordoba",
        "abbreviation": "NIO",
        "symbol": "&#67;&#36;"
      },
      {
        "currency": "Nigeria Naira",
        "abbreviation": "NGN",
        "symbol": "&#8358;"
      },
      {
        "currency": "Norway Krone",
        "abbreviation": "NOK",
        "symbol": "&#107;&#114;"
      },
      {
        "currency": "Oman Rial",
        "abbreviation": "OMR",
        "symbol": "&#65020;"
      },
      {
        "currency": "Pakistan Rupee",
        "abbreviation": "PKR",
        "symbol": "&#8360;"
      },
      {
        "currency": "Panama Balboa",
        "abbreviation": "PAB",
        "symbol": "&#66;&#47;&#46;"
      },
      {
        "currency": "Paraguay Guarani",
        "abbreviation": "PYG",
        "symbol": "&#71;&#115;"
      },
      {
        "currency": "Peru Nuevo Sol",
        "abbreviation": "PEN",
        "symbol": "&#83;&#47;&#46;"
      },
      {
        "currency": "Philippines Peso",
        "abbreviation": "PHP",
        "symbol": "&#8369;"
      },
      {
        "currency": "Poland Zloty",
        "abbreviation": "PLN",
        "symbol": "&#122;&#322;"
      },
      {
        "currency": "Qatar Riyal",
        "abbreviation": "QAR",
        "symbol": "&#65020;"
      },
      {
        "currency": "Romania New Leu",
        "abbreviation": "RON",
        "symbol": "&#108;&#101;&#105;"
      },
      {
        "currency": "Russia Ruble",
        "abbreviation": "RUB",
        "symbol": "&#1088;&#1091;&#1073;"
      },
      {
        "currency": "Saint Helena Pound",
        "abbreviation": "SHP",
        "symbol": "&#163;"
      },
      {
        "currency": "Saudi Arabia Riyal",
        "abbreviation": "SAR",
        "symbol": "&#65020;"
      },
      {
        "currency": "Serbia Dinar",
        "abbreviation": "RSD",
        "symbol": "&#1044;&#1080;&#1085;&#46;"
      },
      {
        "currency": "Seychelles Rupee",
        "abbreviation": "SCR",
        "symbol": "&#8360;"
      },
      {
        "currency": "Singapore Dollar",
        "abbreviation": "SGD",
        "symbol": "&#36;"
      },
      {
        "currency": "Solomon Islands Dollar",
        "abbreviation": "SBD",
        "symbol": "&#36;"
      },
      {
        "currency": "Somalia Shilling",
        "abbreviation": "SOS",
        "symbol": "&#83;"
      },
      {
        "currency": "South Africa Rand",
        "abbreviation": "ZAR",
        "symbol": "&#82;"
      },
      {
        "currency": "Sri Lanka Rupee",
        "abbreviation": "LKR",
        "symbol": "&#8360;"
      },
      {
        "currency": "Sweden Krona",
        "abbreviation": "SEK",
        "symbol": "&#107;&#114;"
      },
      {
        "currency": "Switzerland Franc",
        "abbreviation": "CHF",
        "symbol": "&#67;&#72;&#70;"
      },
      {
        "currency": "Suriname Dollar",
        "abbreviation": "SRD",
        "symbol": "&#36;"
      },
      {
        "currency": "Syria Pound",
        "abbreviation": "SYP",
        "symbol": "&#163;"
      },
      {
        "currency": "Taiwan New Dollar",
        "abbreviation": "TWD",
        "symbol": "&#78;&#84;&#36;"
      },
      {
        "currency": "Thailand Baht",
        "abbreviation": "THB",
        "symbol": "&#3647;"
      },
      {
        "currency": "Trinidad and Tobago Dollar",
        "abbreviation": "TTD",
        "symbol": "&#84;&#84;&#36;"
      },
      {
        "currency": "Turkey Lira",
        "abbreviation": "TRY",
        "symbol": null
      },
      {
        "currency": "Turkey Lira",
        "abbreviation": "TRL",
        "symbol": "&#8356;"
      },
      {
        "currency": "Tuvalu Dollar",
        "abbreviation": "TVD",
        "symbol": "&#36;"
      },
      {
        "currency": "Ukraine Hryvna",
        "abbreviation": "UAH",
        "symbol": "&#8372;"
      },
      {
        "currency": "United Kingdom Pound",
        "abbreviation": "GBP",
        "symbol": "&#163;"
      },
      {
        "currency": "United States Dollar",
        "abbreviation": "USD",
        "symbol": "&#36;"
      },
      {
        "currency": "Uruguay Peso",
        "abbreviation": "UYU",
        "symbol": "&#36;&#85;"
      },
      {
        "currency": "Uzbekistan Som",
        "abbreviation": "UZS",
        "symbol": "&#1083;&#1074;"
      },
      {
        "currency": "Venezuela Bolivar",
        "abbreviation": "VEF",
        "symbol": "&#66;&#115;"
      },
      {
        "currency": "Viet Nam Dong",
        "abbreviation": "VND",
        "symbol": "&#8363;"
      },
      {
        "currency": "Yemen Rial",
        "abbreviation": "YER",
        "symbol": "&#65020;"
      },
      {
        "currency": "Zimbabwe Dollar",
        "abbreviation": "ZWD",
        "symbol": "&#90;&#36;"
      }
    ]
  },

  Icons: {
    generate: function (iconName, isDoubleQuotes, style) {
      var newIconName = iconName.replace("fa-", "");
      var defaultType = "";
      if (Common.Icons.brands.indexOf(newIconName) > -1) {
        defaultType = "-brands";
      }
      else if (Common.Icons.regular.indexOf(newIconName) > -1) {
        defaultType = "-regular"
      }
      else if (Common.Icons.light.indexOf(newIconName) > -1) {
        defaultType = "-light"
      }
      else if (Common.Icons.thin.indexOf(newIconName) > -1) {
        defaultType = "-thin"
      }
      else if (Common.Icons.solid.indexOf(newIconName) > -1) {
        defaultType = "-solid"
      }
      else {
        defaultType = "-regular";
      }

      if (style) {
        var chosenStyle = style.replace("-", "");
        var isFoundInChosenStyle = (Common.Icons[chosenStyle].indexOf(newIconName) > -1)
        if (isFoundInChosenStyle)
          defaultType = "-" + chosenStyle;
      }

      var returning = (isDoubleQuotes ? "<i class='fa" + defaultType + " fa-" + newIconName + "'></i>" : '<i class=\'fa' + defaultType + ' fa-' + newIconName + '\'></i>');
      return returning;
    },
    generateSpinner: function () {
      return '<i class=\'fa-regular fa-spinner fa-spin fa-sm\'></i>';
    },
    generateLoading: function (isSuppressWidth) {
      return '<img src="/img/loading/web-1.gif" class="' + (isSuppressWidth ? 'loading-centered-nowidth' : 'loading-centered') + '">';
    },

    generateAgentIcons: function (browserName, browserVersion, osName, osVersion) {
      var html = '';
      switch (browserName) {
        case 'Chrome':
        case 'Chromium':
          html += '<i class=\'fa fa-chrome\' title=\'' + browserName + ' ' + browserVersion + '\'></i>&nbsp;&nbsp;';
          break;
        case 'Firefox':
        case 'Mozilla':
          html += '<i class=\'fa fa-firefox\' title=\'' + browserName + ' ' + browserVersion + '\'></i>&nbsp;&nbsp;';
          break;
        case 'IEMobile':
        case 'IE':
          html += '<i class=\'fa fa-internet-explorer\' title=\'' + browserName + ' ' + browserVersion + '\'></i>&nbsp;&nbsp;';
          break;
        case 'Edge':
          html += '<i class=\'fa fa-edge\' title=\'' + browserName + ' ' + browserVersion + '\'></i>&nbsp;&nbsp;';
          break;
        case 'Safari':
        case 'Mobile Safari':
          html += '<i class=\'fa fa-safari\' title=\'' + browserName + ' ' + browserVersion + '\'></i>&nbsp;&nbsp;';
          break;
        case 'Opera':
        case 'Opera Mini':
        case 'Opera Mobi':
        case 'Opera Mobile':
        case 'Opera Tablet':
          html += '<i class=\'fa fa-opera\' title=\'' + browserName + ' ' + browserVersion + '\'></i>&nbsp;&nbsp;';
          break;
        default:
          html += '<i class=\'fa fa-question\' title=\'' + browserName + ' ' + browserVersion + '\'></i>&nbsp;';
          break;

      }

      switch (osName) {
        case 'Android':
          html += '<i class=\'fa fa-android\' title=\'' + osName + ' ' + osVersion + '\'></i>';
          break;
        case 'Chromium OS':
          html += '<i class=\'fa fa-chrome\' title=\'' + osName + ' ' + osVersion + '\'></i>';
          break;
        case 'Linux':
          html += '<i class=\'fa fa-linux\' title=\'' + osName + ' ' + osVersion + '\'></i>';
          break;
        case 'Mac OS':
        case 'iOS':
          html += '<i class=\'fa-brands fa-apple\' title=\'' + osName + ' ' + osVersion + '\'></i>';
          break;
        case 'Firefox OS':
          html += '<i class=\'fa fa-firefox\' title=\'' + osName + ' ' + osVersion + '\'></i>';
          break;
        case 'Windows':
        case 'Windows Mobile':
        case 'Windows Phone OS':
          html += '<i class=\'fa fa-windows\' title=\'' + osName + ' ' + osVersion + '\'></i>';
          break;
        case 'BlackBerry':
          html += '<i class=\'fa fa-blackberry\' title=\'' + osName + ' ' + osVersion + '\'></i>';
          break;
        default:
          html += '<i class=\'fa fa-question\' title=\'' + osName + ' ' + osVersion + '\'></i>';
          break;
      }
      return html;
    },
    brands: [
      "42-group",
      "500px",
      "accessible-icon",
      "accusoft",
      "adn",
      "adversal",
      "affiliatetheme",
      "airbnb",
      "algolia",
      "alipay",
      "amazon",
      "amazon-pay",
      "amilia",
      "android",
      "angellist",
      "angrycreative",
      "angular",
      "app-store",
      "app-store-ios",
      "apper",
      "apple",
      "apple-pay",
      "artstation",
      "asymmetrik",
      "atlassian",
      "audible",
      "autoprefixer",
      "avianex",
      "aviato",
      "aws",
      "bandcamp",
      "battle-net",
      "behance",
      "behance-square",
      "bilibili",
      "bimobject",
      "bitbucket",
      "bitcoin",
      "bity",
      "black-tie",
      "blackberry",
      "blogger",
      "blogger-b",
      "bluetooth",
      "bluetooth-b",
      "bootstrap",
      "bots",
      "btc",
      "buffer",
      "buromobelexperte",
      "buy-n-large",
      "buysellads",
      "canadian-maple-leaf",
      "cc-amazon-pay",
      "cc-amex",
      "cc-apple-pay",
      "cc-diners-club",
      "cc-discover",
      "cc-jcb",
      "cc-mastercard",
      "cc-paypal",
      "cc-stripe",
      "cc-visa",
      "centercode",
      "centos",
      "chrome",
      "chromecast",
      "cloudflare",
      "cloudscale",
      "cloudsmith",
      "cloudversify",
      "cmplid",
      "codepen",
      "codiepie",
      "confluence",
      "connectdevelop",
      "contao",
      "cotton-bureau",
      "cpanel",
      "creative-commons",
      "creative-commons-by",
      "creative-commons-nc",
      "creative-commons-nc-eu",
      "creative-commons-nc-jp",
      "creative-commons-nd",
      "creative-commons-pd",
      "creative-commons-pd-alt",
      "creative-commons-remix",
      "creative-commons-sa",
      "creative-commons-sampling",
      "creative-commons-sampling-plus",
      "creative-commons-share",
      "creative-commons-zero",
      "critical-role",
      "css3",
      "css3-alt",
      "cuttlefish",
      "d-and-d",
      "d-and-d-beyond",
      "dailymotion",
      "dashcube",
      "deezer",
      "delicious",
      "deploydog",
      "deskpro",
      "dev",
      "deviantart",
      "dhl",
      "diaspora",
      "digg",
      "digital-ocean",
      "discord",
      "discourse",
      "dochub",
      "docker",
      "draft2digital",
      "dribbble",
      "dribbble-square",
      "dropbox",
      "drupal",
      "dyalog",
      "earlybirds",
      "ebay",
      "edge",
      "edge-legacy",
      "elementor",
      "ello",
      "ember",
      "empire",
      "envira",
      "erlang",
      "ethereum",
      "etsy",
      "evernote",
      "expeditedssl",
      "facebook",
      "facebook-f",
      "facebook-messenger",
      "facebook-square",
      "fantasy-flight-games",
      "fedex",
      "fedora",
      "figma",
      "firefox",
      "firefox-browser",
      "first-order",
      "first-order-alt",
      "firstdraft",
      "flickr",
      "flipboard",
      "fly",
      "font-awesome",
      "fonticons",
      "fonticons-fi",
      "fort-awesome",
      "fort-awesome-alt",
      "forumbee",
      "foursquare",
      "free-code-camp",
      "freebsd",
      "fulcrum",
      "galactic-republic",
      "galactic-senate",
      "get-pocket",
      "gg",
      "gg-circle",
      "git",
      "git-alt",
      "git-square",
      "github",
      "github-alt",
      "github-square",
      "gitkraken",
      "gitlab",
      "gitter",
      "glide",
      "glide-g",
      "gofore",
      "golang",
      "goodreads",

      "goodreads-g",
      "google",
      "google-drive",
      "google-pay",
      "google-play",
      "google-plus",
      "google-plus-g",
      "google-plus-square",
      "google-wallet",
      "gratipay",
      "grav",
      "gripfire",
      "grunt",
      "guilded",
      "gulp",
      "hacker-news",
      "hacker-news-square",
      "hackerrank",
      "hashnode",
      "hips",
      "hire-a-helper",
      "hive",
      "hooli",
      "hornbill",
      "hotjar",
      "houzz",
      "html5",
      "hubspot",
      "ideal",
      "imdb",
      "instagram",
      "instagram-square",
      "instalod",
      "intercom",
      "internet-explorer",
      "invision",
      "ioxhost",
      "itch-io",
      "itunes",
      "itunes-note",
      "java",
      "jedi-order",
      "jenkins",
      "jira",
      "joget",
      "joomla",
      "js",
      "js-square",
      "jsfiddle",
      "kaggle",
      "keybase",
      "keycdn",
      "kickstarter",
      "kickstarter-k",
      "korvue",
      "laravel",
      "lastfm",
      "lastfm-square",
      "leanpub",
      "less",
      "line",
      "linkedin",
      "linkedin-in",
      "linode",
      "linux",
      "lyft",
      "magento",
      "mailchimp",
      "mandalorian",
      "markdown",
      "mastodon",
      "maxcdn",
      "mdb",
      "medapps",
      "medium",
      "medrt",
      "meetup",
      "megaport",
      "mendeley",
      "meta",
      "microblog",
      "microsoft",
      "mix",
      "mixcloud",
      "mixer",
      "mizuni",
      "modx",
      "monero",
      "napster",
      "neos",
      "nimblr",
      "node",
      "node-js",
      "npm",
      "ns8",
      "nutritionix",
      "octopus-deploy",
      "odnoklassniki",
      "odnoklassniki-square",
      "old-republic",
      "opencart",
      "openid",
      "opera",
      "optin-monster",
      "orcid",
      "osi",
      "padlet",
      "page4",
      "pagelines",
      "palfed",
      "patreon",
      "paypal",
      "perbyte",
      "periscope",
      "phabricator",
      "phoenix-framework",
      "phoenix-squadron",
      "php",
      "pied-piper",
      "pied-piper-alt",
      "pied-piper-hat",
      "pied-piper-pp",
      "pied-piper-square",
      "pinterest",
      "pinterest-p",
      "pinterest-square",
      "pix",
      "playstation",
      "product-hunt",
      "pushed",
      "python",
      "qq",
      "quinscape",
      "quora",
      "r-project",
      "raspberry-pi",
      "ravelry",
      "react",
      "reacteurope",
      "readme",
      "rebel",
      "red-river",
      "reddit",
      "reddit-alien",
      "reddit-square",
      "redhat",
      "renren",
      "replyd",
      "researchgate",
      "resolving",
      "rev",
      "rocketchat",
      "rockrms",
      "rust",
      "safari",
      "salesforce",
      "sass",
      "schlix",
      "scribd",
      "searchengin",
      "sellcast",
      "sellsy",
      "servicestack",
      "shirtsinbulk",
      "shopify",
      "shopware",
      "simplybuilt",
      "sistrix",
      "sith",
      "sitrox",
      "sketch",
      "skyatlas",
      "skype",
      "slack",
      "slideshare",
      "snapchat",
      "snapchat-square",
      "soundcloud",
      "sourcetree",
      "speakap",

      "speaker-deck",
      "spotify",
      "square-font-awesome",
      "square-font-awesome-stroke",
      "squarespace",
      "stack-exchange",
      "stack-overflow",
      "stackpath",
      "staylinked",
      "steam",
      "steam-square",
      "steam-symbol",
      "sticker-mule",
      "strava",
      "stripe",
      "stripe-s",
      "studiovinari",
      "stumbleupon",
      "stumbleupon-circle",
      "superpowers",
      "supple",
      "suse",
      "swift",
      "symfony",
      "teamspeak",
      "telegram",
      "tencent-weibo",
      "the-red-yeti",
      "themeco",
      "themeisle",
      "think-peaks",
      "tiktok",
      "trade-federation",
      "trello",
      "tumblr",
      "tumblr-square",
      "twitch",
      "twitter",
      "twitter-square",
      "typo3",
      "uber",
      "ubuntu",
      "uikit",
      "umbraco",
      "uncharted",
      "uniregistry",
      "unity",
      "unsplash",
      "untappd",
      "ups",
      "usb",
      "usps",
      "ussunnah",
      "vaadin",
      "viacoin",
      "viadeo",
      "viadeo-square",
      "viber",
      "vimeo",
      "vimeo-square",
      "vimeo-v",
      "vine",
      "vk",
      "vnv",
      "vuejs",
      "watchman-monitoring",
      "waze",
      "weebly",
      "weibo",
      "weixin",
      "whatsapp",
      "whatsapp-square",
      "whmcs",
      "wikipedia-w",
      "windows",
      "wirsindhandwerk",
      "wix",
      "wizards-of-the-coast",
      "wodu",
      "wolf-pack-battalion",
      "wordpress",
      "wordpress-simple",
      "wpbeginner",
      "wpexplorer",
      "wpforms",
      "wpressr",
      "xbox",
      "xing",
      "xing-square",
      "y-combinator",
      "yahoo",
      "yammer",
      "yandex",
      "yandex-international",
      "yarn",
      "yelp",
      "yoast",
      "youtube",
      "youtube-square",
      "zhihu"

    ],
    regular: [
      "0",
      "00",
      "1",
      "2",
      "3",
      "360-degrees",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "a",
      "abacus",
      "accent-grave",
      "acorn",
      "address-book",
      "address-card",
      "air-conditioner",
      "airplay",
      "alarm-clock",
      "alarm-exclamation",
      "alarm-plus",
      "alarm-snooze",
      "album",
      "album-circle-plus",
      "album-circle-user",
      "album-collection",
      "album-collection-circle-plus",
      "album-collection-circle-user",
      "alicorn",
      "alien",
      "alien-8bit",
      "align-center",
      "align-justify",
      "align-left",
      "align-right",
      "align-slash",
      "alt",
      "amp-guitar",
      "ampersand",
      "anchor",
      "angel",
      "angle",
      "angle-90",
      "angle-down",
      "angle-left",
      "angle-right",
      "angle-up",
      "angles-down",
      "angles-left",
      "angles-right",
      "angles-up",
      "ankh",
      "apartment",
      "aperture",
      "apostrophe",
      "apple-core",
      "apple-whole",
      "archway",
      "arrow-down",
      "arrow-down-1-9",
      "arrow-down-9-1",
      "arrow-down-a-z",
      "arrow-down-arrow-up",
      "arrow-down-big-small",
      "arrow-down-from-dotted-line",
      "arrow-down-from-line",
      "arrow-down-left",
      "arrow-down-left-and-arrow-up-right-to-center",
      "arrow-down-long",
      "arrow-down-right",
      "arrow-down-short-wide",
      "arrow-down-small-big",
      "arrow-down-square-triangle",
      "arrow-down-to-bracket",
      "arrow-down-to-dotted-line",
      "arrow-down-to-line",
      "arrow-down-to-square",
      "arrow-down-triangle-square",
      "arrow-down-wide-short",
      "arrow-down-z-a",
      "arrow-left",
      "arrow-left-from-line",
      "arrow-left-long",
      "arrow-left-long-to-line",
      "arrow-left-to-line",
      "arrow-pointer",
      "arrow-right",
      "arrow-right-arrow-left",
      "arrow-right-from-bracket",
      "arrow-right-from-line",
      "arrow-right-long",
      "arrow-right-long-to-line",
      "arrow-right-to-bracket",
      "arrow-right-to-line",
      "arrow-rotate-left",
      "arrow-rotate-right",
      "arrow-trend-down",
      "arrow-trend-up",
      "arrow-turn-down",
      "arrow-turn-down-left",
      "arrow-turn-down-right",
      "arrow-turn-up",
      "arrow-up",
      "arrow-up-1-9",
      "arrow-up-9-1",
      "arrow-up-a-z",
      "arrow-up-arrow-down",
      "arrow-up-big-small",
      "arrow-up-from-bracket",
      "arrow-up-from-dotted-line",
      "arrow-up-from-line",
      "arrow-up-from-square",
      "arrow-up-left",
      "arrow-up-left-from-circle",
      "arrow-up-long",
      "arrow-up-right",
      "arrow-up-right-and-arrow-down-left-from-center",
      "arrow-up-right-from-square",
      "arrow-up-short-wide",
      "arrow-up-small-big",
      "arrow-up-square-triangle",
      "arrow-up-to-dotted-line",
      "arrow-up-to-line",
      "arrow-up-triangle-square",
      "arrow-up-wide-short",
      "arrow-up-z-a",
      "arrows-cross",
      "arrows-from-dotted-line",
      "arrows-from-line",
      "arrows-left-right",
      "arrows-maximize",
      "arrows-minimize",
      "arrows-repeat",
      "arrows-repeat-1",
      "arrows-retweet",
      "arrows-rotate",
      "arrows-to-dotted-line",
      "arrows-to-line",
      "arrows-up-down",
      "arrows-up-down-left-right",
      "asterisk",
      "at",
      "atom",
      "atom-simple",
      "audio-description",
      "audio-description-slash",
      "austral-sign",
      "avocado",
      "award",
      "award-simple",
      "axe",
      "axe-battle",
      "b",
      "baby",
      "baby-carriage",
      "backpack",
      "backward",
      "backward-fast",
      "backward-step",
      "bacon",
      "bacteria",
      "bacterium",
      "badge",
      "badge-check",
      "badge-dollar",
      "badge-percent",
      "badge-sheriff",
      "badger-honey",
      "badminton",
      "bag-shopping",
      "bagel",
      "bags-shopping",
      "baguette",
      "bahai",
      "baht-sign",
      "ball-pile",
      "balloon",
      "balloons",
      "ballot",
      "ballot-check",
      "ban",
      "ban-bug",
      "ban-parking",
      "ban-smoking",
      "banana",
      "bandage",
      "bangladeshi-taka-sign",
      "banjo",
      "barcode",
      "barcode-read",
      "barcode-scan",
      "bars",
      "bars-filter",
      "bars-progress",
      "bars-sort",
      "bars-staggered",
      "baseball",
      "baseball-bat-ball",
      "basket-shopping",
      "basket-shopping-simple",
      "basketball",
      "basketball-hoop",
      "bat",
      "bath",
      "battery-bolt",
      "battery-empty",
      "battery-exclamation",
      "battery-full",
      "battery-half",
      "battery-low",
      "battery-quarter",
      "battery-slash",
      "battery-three-quarters",
      "bed",
      "bed-bunk",
      "bed-empty",
      "bed-front",
      "bed-pulse",
      "bee",
      "beer-mug",
      "beer-mug-empty",
      "bell",
      "bell-concierge",
      "bell-exclamation",
      "bell-on",
      "bell-plus",
      "bell-school",
      "bell-school-slash",
      "bell-slash",
      "bells",
      "bench-tree",
      "bezier-curve",
      "bicycle",
      "binary",
      "binary-circle-check",
      "binary-lock",
      "binary-slash",
      "binoculars",
      "biohazard",
      "bird",
      "bitcoin-sign",
      "blanket",
      "blanket-fire",
      "blender",
      "blender-phone",
      "blinds",
      "blinds-open",
      "blinds-raised",
      "block",
      "block-brick",
      "block-brick-fire",
      "block-question",
      "block-quote",
      "blog",
      "blueberries",
      "bluetooth",
      "bold",
      "bolt",
      "bolt-auto",
      "bolt-lightning",
      "bolt-slash",
      "bomb",
      "bone",
      "bone-break",
      "bong",
      "book",
      "book-arrow-right",
      "book-arrow-up",
      "book-atlas",
      "book-bible",
      "book-blank",
      "book-bookmark",
      "book-circle-arrow-right",
      "book-circle-arrow-up",
      "book-copy",
      "book-font",
      "book-heart",
      "book-journal-whills",
      "book-medical",
      "book-open",
      "book-open-cover",
      "book-open-reader",
      "book-quran",
      "book-section",
      "book-skull",
      "book-sparkles",
      "book-tanakh",
      "book-user",
      "bookmark",
      "bookmark-slash",
      "books",
      "books-medical",
      "boombox",
      "boot",
      "boot-heeled",
      "booth-curtain",
      "border-all",
      "border-bottom",
      "border-bottom-right",
      "border-center-h",
      "border-center-v",
      "border-inner",
      "border-left",
      "border-none",
      "border-outer",
      "border-right",
      "border-top",
      "border-top-left",
      "bow-arrow",
      "bowl-chopsticks",
      "bowl-chopsticks-noodles",
      "bowl-hot",
      "bowl-rice",
      "bowl-scoop",
      "bowl-scoops",
      "bowl-soft-serve",
      "bowl-spoon",
      "bowling-ball",
      "bowling-ball-pin",
      "bowling-pins",
      "box",
      "box-archive",
      "box-ballot",
      "box-check",
      "box-circle-check",
      "box-dollar",
      "box-heart",
      "box-open",
      "box-open-full",
      "box-taped",
      "box-tissue",
      "boxes-stacked",
      "boxing-glove",
      "bracket-curly",
      "bracket-curly-right",
      "bracket-round",
      "bracket-round-right",
      "bracket-square",
      "bracket-square-right",
      "brackets-curly",
      "brackets-round",
      "brackets-square",
      "braille",
      "brain",
      "brain-arrow-curved-right",
      "brain-circuit",
      "brake-warning",
      "brazilian-real-sign",
      "bread-loaf",
      "bread-slice",
      "bread-slice-butter",
      "briefcase",
      "briefcase-arrow-right",
      "briefcase-blank",
      "briefcase-medical",
      "brightness",
      "brightness-low",
      "bring-forward",
      "bring-front",
      "broccoli",
      "broom",
      "broom-ball",
      "browser",
      "browsers",
      "brush",
      "bug",
      "bug-slash",
      "building",
      "building-columns",
      "buildings",
      "bullhorn",
      "bullseye",
      "bullseye-arrow",
      "bullseye-pointer",
      "burger",
      "burger-cheese",
      "burger-fries",
      "burger-glass",
      "burger-lettuce",
      "burger-soda",
      "burrito",
      "bus",
      "bus-school",
      "bus-simple",
      "business-time",
      "butter",
      "c",
      "cabin",
      "cabinet-filing",
      "cable-car",
      "cactus",
      "cake-candles",
      "cake-slice",
      "calculator",
      "calculator-simple",
      "calendar",
      "calendar-arrow-down",
      "calendar-arrow-up",
      "calendar-check",
      "calendar-circle-exclamation",
      "calendar-circle-minus",
      "calendar-circle-plus",
      "calendar-circle-user",
      "calendar-clock",
      "calendar-day",
      "calendar-days",
      "calendar-exclamation",
      "calendar-heart",
      "calendar-image",
      "calendar-lines",
      "calendar-lines-pen",
      "calendar-minus",
      "calendar-pen",
      "calendar-plus",
      "calendar-range",
      "calendar-star",
      "calendar-week",
      "calendar-xmark",
      "calendars",
      "camcorder",
      "camera",
      "camera-cctv",
      "camera-movie",
      "camera-polaroid",
      "camera-retro",
      "camera-rotate",
      "camera-security",
      "camera-slash",
      "camera-viewfinder",
      "camera-web",
      "camera-web-slash",
      "campfire",
      "campground",
      "can-food",
      "candle-holder",
      "candy",
      "candy-bar",
      "candy-cane",
      "candy-corn",
      "cannabis",
      "capsules",
      "car",
      "car-battery",
      "car-bolt",
      "car-building",
      "car-bump",
      "car-bus",
      "car-circle-bolt",
      "car-crash",
      "car-garage",
      "car-mirrors",
      "car-rear",
      "car-side",
      "car-side-bolt",
      "car-tilt",
      "car-wash",
      "car-wrench",
      "caravan",
      "caravan-simple",
      "card-club",
      "card-diamond",
      "card-heart",
      "card-spade",
      "cards",
      "caret-down",
      "caret-left",
      "caret-right",
      "caret-up",
      "carrot",
      "cars",
      "cart-arrow-down",
      "cart-arrow-up",
      "cart-circle-arrow-down",
      "cart-circle-arrow-up",
      "cart-circle-check",
      "cart-circle-exclamation",
      "cart-circle-plus",
      "cart-circle-xmark",
      "cart-flatbed",
      "cart-flatbed-boxes",
      "cart-flatbed-empty",
      "cart-flatbed-suitcase",
      "cart-minus",
      "cart-plus",
      "cart-shopping",
      "cart-shopping-fast",
      "cart-xmark",
      "cash-register",
      "cassette-betamax",
      "cassette-tape",
      "cassette-vhs",
      "castle",
      "cat",
      "cat-space",
      "cauldron",
      "cedi-sign",
      "cent-sign",
      "certificate",
      "chair",
      "chair-office",
      "chalkboard",
      "chalkboard-user",
      "champagne-glass",
      "champagne-glasses",
      "charging-station",
      "chart-area",
      "chart-bar",
      "chart-bullet",
      "chart-candlestick",
      "chart-column",
      "chart-gantt",
      "chart-line",
      "chart-line-down",
      "chart-line-up",
      "chart-mixed",
      "chart-network",
      "chart-pie",
      "chart-pie-simple",
      "chart-pyramid",
      "chart-radar",
      "chart-scatter",
      "chart-scatter-3d",
      "chart-scatter-bubble",
      "chart-simple",
      "chart-simple-horizontal",
      "chart-tree-map",
      "chart-user",
      "chart-waterfall",
      "check",
      "check-double",
      "check-to-slot",
      "cheese",
      "cheese-swiss",
      "cherries",
      "chess",
      "chess-bishop",
      "chess-bishop-piece",
      "chess-board",
      "chess-clock",
      "chess-clock-flip",
      "chess-king",
      "chess-king-piece",
      "chess-knight",
      "chess-knight-piece",
      "chess-pawn",
      "chess-pawn-piece",
      "chess-queen",
      "chess-queen-piece",
      "chess-rook",
      "chess-rook-piece",
      "chestnut",
      "chevron-down",
      "chevron-left",
      "chevron-right",
      "chevron-up",
      "chevrons-down",
      "chevrons-left",
      "chevrons-right",
      "chevrons-up",
      "child",
      "chimney",
      "chopsticks",
      "church",
      "circle",
      "circle-0",
      "circle-1",
      "circle-2",
      "circle-3",
      "circle-4",
      "circle-5",
      "circle-6",
      "circle-7",
      "circle-8",
      "circle-9",
      "circle-a",
      "circle-ampersand",
      "circle-arrow-down",
      "circle-arrow-down-left",
      "circle-arrow-down-right",
      "circle-arrow-left",
      "circle-arrow-right",
      "circle-arrow-up",
      "circle-arrow-up-left",
      "circle-arrow-up-right",
      "circle-b",
      "circle-bolt",
      "circle-book-open",
      "circle-bookmark",
      "circle-c",
      "circle-calendar",
      "circle-camera",
      "circle-caret-down",
      "circle-caret-left",
      "circle-caret-right",
      "circle-caret-up",
      "circle-check",
      "circle-chevron-down",
      "circle-chevron-left",
      "circle-chevron-right",
      "circle-chevron-up",
      "circle-d",
      "circle-dashed",
      "circle-divide",
      "circle-dollar",
      "circle-dollar-to-slot",
      "circle-dot",
      "circle-down",
      "circle-down-left",
      "circle-down-right",
      "circle-e",
      "circle-ellipsis",
      "circle-ellipsis-vertical",
      "circle-envelope",
      "circle-exclamation",
      "circle-exclamation-check",
      "circle-f",
      "circle-g",
      "circle-h",
      "circle-half",
      "circle-half-stroke",
      "circle-heart",
      "circle-i",
      "circle-info",
      "circle-j",
      "circle-k",
      "circle-l",
      "circle-left",
      "circle-location-arrow",
      "circle-m",
      "circle-microphone",
      "circle-microphone-lines",
      "circle-minus",
      "circle-n",
      "circle-notch",
      "circle-o",
      "circle-p",
      "circle-parking",
      "circle-pause",
      "circle-phone",
      "circle-phone-flip",
      "circle-phone-hangup",
      "circle-play",
      "circle-plus",
      "circle-q",
      "circle-quarter",
      "circle-quarters",
      "circle-question",
      "circle-r",
      "circle-radiation",
      "circle-right",
      "circle-s",
      "circle-small",
      "circle-sort",
      "circle-sort-down",
      "circle-sort-up",
      "circle-star",
      "circle-stop",
      "circle-t",
      "circle-three-quarters",
      "circle-trash",
      "circle-u",
      "circle-up",
      "circle-up-left",
      "circle-up-right",
      "circle-user",
      "circle-v",
      "circle-video",
      "circle-w",
      "circle-waveform-lines",
      "circle-x",
      "circle-xmark",
      "circle-y",
      "circle-z",
      "citrus",
      "citrus-slice",
      "city",
      "clapperboard",
      "clapperboard-play",
      "clarinet",
      "claw-marks",
      "clipboard",
      "clipboard-check",
      "clipboard-list",
      "clipboard-list-check",
      "clipboard-medical",
      "clipboard-prescription",
      "clipboard-user",
      "clock",
      "clock-desk",
      "clock-eight",
      "clock-eight-thirty",
      "clock-eleven",
      "clock-eleven-thirty",
      "clock-five",
      "clock-five-thirty",
      "clock-four-thirty",
      "clock-nine",
      "clock-nine-thirty",
      "clock-one",
      "clock-one-thirty",
      "clock-rotate-left",
      "clock-seven",
      "clock-seven-thirty",
      "clock-six",
      "clock-six-thirty",
      "clock-ten",
      "clock-ten-thirty",
      "clock-three",
      "clock-three-thirty",
      "clock-twelve",
      "clock-twelve-thirty",
      "clock-two",
      "clock-two-thirty",
      "clone",
      "closed-captioning",
      "closed-captioning-slash",
      "clothes-hanger",
      "cloud",
      "cloud-arrow-down",
      "cloud-arrow-up",
      "cloud-bolt",
      "cloud-bolt-moon",
      "cloud-bolt-sun",
      "cloud-check",
      "cloud-drizzle",
      "cloud-exclamation",
      "cloud-fog",
      "cloud-hail",
      "cloud-hail-mixed",
      "cloud-meatball",
      "cloud-minus",
      "cloud-moon",
      "cloud-moon-rain",
      "cloud-music",
      "cloud-plus",
      "cloud-question",
      "cloud-rain",
      "cloud-rainbow",
      "cloud-showers",
      "cloud-showers-heavy",
      "cloud-slash",
      "cloud-sleet",
      "cloud-snow",
      "cloud-sun",
      "cloud-sun-rain",
      "cloud-word",
      "cloud-xmark",
      "clouds",
      "clouds-moon",
      "clouds-sun",
      "clover",
      "club",
      "coconut",
      "code",
      "code-branch",
      "code-commit",
      "code-compare",
      "code-fork",
      "code-merge",
      "code-pull-request",
      "code-pull-request-closed",
      "code-pull-request-draft",
      "code-simple",
      "coffee-bean",
      "coffee-beans",
      "coffee-pot",
      "coffin",
      "coffin-cross",
      "coin",
      "coin-blank",
      "coin-front",
      "coin-vertical",
      "coins",
      "colon",
      "colon-sign",
      "columns-3",
      "comet",
      "comma",
      "command",
      "comment",
      "comment-arrow-down",
      "comment-arrow-up",
      "comment-arrow-up-right",
      "comment-captions",
      "comment-check",
      "comment-code",
      "comment-dollar",
      "comment-dots",
      "comment-exclamation",
      "comment-image",
      "comment-lines",
      "comment-medical",
      "comment-middle",
      "comment-middle-top",
      "comment-minus",
      "comment-music",
      "comment-pen",
      "comment-plus",
      "comment-question",
      "comment-quote",
      "comment-slash",
      "comment-smile",
      "comment-sms",
      "comment-text",
      "comment-xmark",
      "comments",
      "comments-dollar",
      "comments-question",
      "comments-question-check",
      "compact-disc",
      "compass",
      "compass-drafting",
      "compass-slash",
      "compress",
      "compress-wide",
      "computer-classic",
      "computer-mouse",
      "computer-mouse-scrollwheel",
      "computer-speaker",
      "container-storage",
      "conveyor-belt",
      "conveyor-belt-boxes",
      "conveyor-belt-empty",
      "cookie",
      "cookie-bite",
      "copy",
      "copyright",
      "corn",
      "corner",
      "couch",
      "cow",
      "cowbell",
      "cowbell-circle-plus",
      "crab",
      "crate-apple",
      "crate-empty",
      "credit-card",
      "credit-card-blank",
      "credit-card-front",
      "cricket-bat-ball",
      "croissant",
      "crop",
      "crop-simple",
      "cross",
      "crosshairs",
      "crow",
      "crown",
      "crutch",
      "crutches",
      "cruzeiro-sign",
      "crystal-ball",
      "cube",
      "cubes",
      "cucumber",
      "cup-straw",
      "cup-straw-swoosh",
      "cup-togo",
      "cupcake",
      "curling-stone",
      "custard",
      "d",
      "dagger",
      "dash",
      "database",
      "deer",
      "deer-rudolph",
      "delete-left",
      "delete-right",
      "democrat",
      "desktop",
      "desktop-arrow-down",
      "dharmachakra",
      "diagram-cells",
      "diagram-lean-canvas",
      "diagram-nested",
      "diagram-next",
      "diagram-predecessor",
      "diagram-previous",
      "diagram-project",
      "diagram-sankey",
      "diagram-subtask",
      "diagram-successor",
      "diagram-venn",
      "dial",
      "dial-high",
      "dial-low",
      "dial-max",
      "dial-med",
      "dial-med-low",
      "dial-min",
      "dial-off",
      "diamond",
      "diamond-exclamation",
      "diamond-turn-right",
      "dice",
      "dice-d10",
      "dice-d12",
      "dice-d20",
      "dice-d4",
      "dice-d6",
      "dice-d8",
      "dice-five",
      "dice-four",
      "dice-one",
      "dice-six",
      "dice-three",
      "dice-two",
      "diploma",
      "disc-drive",
      "disease",
      "display",
      "display-arrow-down",
      "display-code",
      "display-medical",
      "display-slash",
      "distribute-spacing-horizontal",
      "distribute-spacing-vertical",
      "ditto",
      "divide",
      "dna",
      "do-not-enter",
      "dog",
      "dog-leashed",
      "dollar-sign",
      "dolly",
      "dolly-empty",
      "dolphin",
      "dong-sign",
      "donut",
      "door-closed",
      "door-open",
      "dove",
      "down",
      "down-from-dotted-line",
      "down-from-line",
      "down-left",
      "down-left-and-up-right-to-center",
      "down-long",
      "down-right",
      "down-to-dotted-line",
      "down-to-line",
      "download",
      "dragon",
      "draw-circle",
      "draw-polygon",
      "draw-square",
      "dreidel",
      "drone",
      "drone-front",
      "droplet",
      "droplet-degree",
      "droplet-percent",
      "droplet-slash",
      "drum",
      "drum-steelpan",
      "drumstick",
      "drumstick-bite",
      "dryer",
      "dryer-heat",
      "duck",
      "dumbbell",
      "dumpster",
      "dumpster-fire",
      "dungeon",
      "e",
      "ear",
      "ear-deaf",
      "ear-listen",
      "ear-muffs",
      "earth-africa",
      "earth-americas",
      "earth-asia",
      "earth-europe",
      "earth-oceania",
      "eclipse",
      "egg",
      "egg-fried",
      "eggplant",
      "eject",
      "elephant",
      "elevator",
      "ellipsis",
      "ellipsis-stroke",
      "ellipsis-stroke-vertical",
      "ellipsis-vertical",
      "empty-set",
      "engine",
      "engine-warning",
      "envelope",
      "envelope-dot",
      "envelope-open",
      "envelope-open-dollar",
      "envelope-open-text",
      "envelopes",
      "envelopes-bulk",
      "equals",
      "eraser",
      "escalator",
      "ethernet",
      "euro-sign",
      "exclamation",
      "expand",
      "expand-wide",
      "eye",
      "eye-dropper",
      "eye-dropper-full",
      "eye-dropper-half",
      "eye-evil",
      "eye-low-vision",
      "eye-slash",
      "eyes",
      "f",
      "face-angry",
      "face-angry-horns",
      "face-anguished",
      "face-anxious-sweat",
      "face-astonished",
      "face-awesome",
      "face-beam-hand-over-mouth",
      "face-clouds",
      "face-confounded",
      "face-confused",
      "face-cowboy-hat",
      "face-diagonal-mouth",
      "face-disappointed",
      "face-disguise",
      "face-dizzy",
      "face-dotted",
      "face-downcast-sweat",
      "face-drooling",
      "face-exhaling",
      "face-explode",
      "face-expressionless",
      "face-eyes-xmarks",
      "face-fearful",
      "face-flushed",
      "face-frown",
      "face-frown-open",
      "face-frown-slight",
      "face-glasses",
      "face-grimace",
      "face-grin",
      "face-grin-beam",
      "face-grin-beam-sweat",
      "face-grin-hearts",
      "face-grin-squint",
      "face-grin-squint-tears",
      "face-grin-stars",
      "face-grin-tears",
      "face-grin-tongue",
      "face-grin-tongue-squint",
      "face-grin-tongue-wink",
      "face-grin-wide",
      "face-grin-wink",
      "face-hand-over-mouth",
      "face-hand-peeking",
      "face-hand-yawn",
      "face-head-bandage",
      "face-holding-back-tears",
      "face-hushed",
      "face-icicles",
      "face-kiss",
      "face-kiss-beam",
      "face-kiss-closed-eyes",
      "face-kiss-wink-heart",
      "face-laugh",
      "face-laugh-beam",
      "face-laugh-squint",
      "face-laugh-wink",
      "face-lying",
      "face-mask",
      "face-meh",
      "face-meh-blank",
      "face-melting",
      "face-monocle",
      "face-nauseated",
      "face-nose-steam",
      "face-party",
      "face-pensive",
      "face-persevering",
      "face-pleading",
      "face-pouting",
      "face-raised-eyebrow",
      "face-relieved",
      "face-rolling-eyes",
      "face-sad-cry",
      "face-sad-sweat",
      "face-sad-tear",
      "face-saluting",
      "face-scream",
      "face-shush",
      "face-sleeping",
      "face-sleepy",
      "face-smile",
      "face-smile-beam",
      "face-smile-halo",
      "face-smile-hearts",
      "face-smile-horns",
      "face-smile-plus",
      "face-smile-relaxed",
      "face-smile-tear",
      "face-smile-tongue",
      "face-smile-upside-down",
      "face-smile-wink",
      "face-smiling-hands",
      "face-smirking",
      "face-spiral-eyes",
      "face-sunglasses",
      "face-surprise",
      "face-swear",
      "face-thermometer",
      "face-thinking",
      "face-tired",
      "face-tissue",
      "face-tongue-money",
      "face-tongue-sweat",
      "face-unamused",
      "face-viewfinder",
      "face-vomit",
      "face-weary",
      "face-woozy",
      "face-worried",
      "face-zany",
      "face-zipper",
      "falafel",
      "family",
      "family-dress",
      "family-pants",
      "fan",
      "fan-table",
      "farm",
      "faucet",
      "faucet-drip",
      "fax",
      "feather",
      "feather-pointed",
      "fence",
      "ferris-wheel",
      "field-hockey-stick-ball",
      "file",
      "file-arrow-down",
      "file-arrow-up",
      "file-audio",
      "file-binary",
      "file-certificate",
      "file-chart-column",
      "file-chart-pie",
      "file-check",
      "file-circle-info",
      "file-circle-plus",
      "file-code",
      "file-contract",
      "file-csv",
      "file-dashed-line",
      "file-excel",
      "file-exclamation",
      "file-export",
      "file-heart",
      "file-image",
      "file-import",
      "file-invoice",
      "file-invoice-dollar",
      "file-lines",
      "file-lock",
      "file-magnifying-glass",
      "file-medical",
      "file-minus",
      "file-music",
      "file-pdf",
      "file-pen",
      "file-plus",
      "file-plus-minus",
      "file-powerpoint",
      "file-prescription",
      "file-signature",
      "file-slash",
      "file-spreadsheet",
      "file-user",
      "file-video",
      "file-waveform",
      "file-word",
      "file-xmark",
      "file-zipper",
      "files",
      "files-medical",
      "fill",
      "fill-drip",
      "film",
      "film-canister",
      "film-simple",
      "film-slash",
      "films",
      "filter",
      "filter-circle-dollar",
      "filter-circle-xmark",
      "filter-list",
      "filter-slash",
      "filters",
      "fingerprint",
      "fire",
      "fire-extinguisher",
      "fire-flame",
      "fire-flame-curved",
      "fire-flame-simple",
      "fire-hydrant",
      "fire-smoke",
      "fireplace",
      "fish",
      "fish-bones",
      "fish-cooked",
      "fishing-rod",
      "flag",
      "flag-checkered",
      "flag-pennant",
      "flag-swallowtail",
      "flag-usa",
      "flashlight",
      "flask",
      "flask-round-poison",
      "flask-round-potion",
      "flatbread",
      "flatbread-stuffed",
      "floppy-disk",
      "floppy-disk-circle-arrow-right",
      "floppy-disk-circle-xmark",
      "floppy-disk-pen",
      "floppy-disks",
      "florin-sign",
      "flower",
      "flower-daffodil",
      "flower-tulip",
      "flute",
      "flux-capacitor",
      "flying-disc",
      "folder",
      "folder-arrow-down",
      "folder-arrow-up",
      "folder-blank",
      "folder-bookmark",
      "folder-gear",
      "folder-grid",
      "folder-heart",
      "folder-image",
      "folder-magnifying-glass",
      "folder-medical",
      "folder-minus",
      "folder-music",
      "folder-open",
      "folder-plus",
      "folder-tree",
      "folder-user",
      "folder-xmark",
      "folders",
      "fondue-pot",
      "font",
      "font-awesome",
      "font-case",
      "football",
      "football-helmet",
      "fork",
      "fork-knife",
      "forklift",
      "fort",
      "forward",
      "forward-fast",
      "forward-step",
      "frame",
      "franc-sign",
      "french-fries",
      "frog",
      "function",
      "futbol",
      "g",
      "galaxy",
      "gallery-thumbnails",
      "game-board",
      "game-board-simple",
      "game-console-handheld",
      "gamepad",
      "gamepad-modern",
      "garage",
      "garage-car",
      "garage-open",
      "garlic",
      "gas-pump",
      "gas-pump-slash",
      "gauge",
      "gauge-circle-bolt",
      "gauge-circle-minus",
      "gauge-circle-plus",
      "gauge-high",
      "gauge-low",
      "gauge-max",
      "gauge-min",
      "gauge-simple",
      "gauge-simple-high",
      "gauge-simple-low",
      "gauge-simple-max",
      "gauge-simple-min",
      "gavel",
      "gear",
      "gears",
      "gem",
      "genderless",
      "ghost",
      "gif",
      "gift",
      "gift-card",
      "gifts",
      "gingerbread-man",
      "glass",
      "glass-citrus",
      "glass-empty",
      "glass-half",
      "glasses",
      "glasses-round",
      "globe",
      "globe-snow",
      "globe-stand",
      "goal-net",
      "golf-ball-tee",
      "golf-club",
      "golf-flag-hole",
      "gopuram",
      "graduation-cap",
      "gramophone",
      "grapes",
      "grate",
      "grate-droplet",
      "greater-than",
      "greater-than-equal",
      "grid",
      "grid-2",
      "grid-2-plus",
      "grid-4",
      "grid-5",
      "grid-dividers",
      "grid-horizontal",
      "grip",
      "grip-dots",
      "grip-dots-vertical",
      "grip-lines",
      "grip-lines-vertical",
      "grip-vertical",
      "guarani-sign",
      "guitar",
      "guitar-electric",
      "guitars",
      "gun",
      "gun-slash",
      "gun-squirt",
      "h",
      "h1",
      "h2",
      "h3",
      "h4",
      "h5",
      "h6",
      "hammer",
      "hammer-crash",
      "hammer-war",
      "hamsa",
      "hand",
      "hand-back-fist",
      "hand-back-point-down",
      "hand-back-point-left",
      "hand-back-point-ribbon",
      "hand-back-point-right",
      "hand-back-point-up",
      "hand-dots",
      "hand-fingers-crossed",
      "hand-fist",
      "hand-heart",
      "hand-holding",
      "hand-holding-box",
      "hand-holding-dollar",
      "hand-holding-droplet",
      "hand-holding-heart",
      "hand-holding-magic",
      "hand-holding-medical",
      "hand-holding-seedling",
      "hand-holding-skull",
      "hand-horns",
      "hand-lizard",
      "hand-love",
      "hand-middle-finger",
      "hand-peace",
      "hand-point-down",
      "hand-point-left",
      "hand-point-ribbon",
      "hand-point-right",
      "hand-point-up",
      "hand-pointer",
      "hand-scissors",
      "hand-sparkles",
      "hand-spock",
      "hand-wave",
      "hands",
      "hands-asl-interpreting",
      "hands-bubbles",
      "hands-clapping",
      "hands-holding",
      "hands-holding-diamond",
      "hands-holding-dollar",
      "hands-holding-heart",
      "hands-praying",
      "handshake",
      "handshake-angle",
      "handshake-simple",
      "handshake-simple-slash",
      "handshake-slash",
      "hanukiah",
      "hard-drive",
      "hashtag",
      "hashtag-lock",
      "hat-chef",
      "hat-cowboy",
      "hat-cowboy-side",
      "hat-santa",
      "hat-winter",
      "hat-witch",
      "hat-wizard",
      "head-side",
      "head-side-brain",
      "head-side-cough",
      "head-side-cough-slash",
      "head-side-goggles",
      "head-side-headphones",
      "head-side-heart",
      "head-side-mask",
      "head-side-medical",
      "head-side-virus",
      "heading",
      "headphones",
      "headphones-simple",
      "headset",
      "heart",
      "heart-crack",
      "heart-half",
      "heart-half-stroke",
      "heart-pulse",
      "heat",
      "helicopter",
      "helmet-battle",
      "helmet-safety",
      "hexagon",
      "hexagon-check",
      "hexagon-divide",
      "hexagon-exclamation",
      "hexagon-minus",
      "hexagon-plus",
      "hexagon-xmark",
      "high-definition",
      "highlighter",
      "highlighter-line",
      "hippo",
      "hockey-mask",
      "hockey-puck",
      "hockey-stick-puck",
      "hockey-sticks",
      "holly-berry",
      "honey-pot",
      "hood-cloak",
      "horizontal-rule",
      "horse",
      "horse-head",
      "horse-saddle",
      "hose",
      "hose-reel",
      "hospital",
      "hospital-user",
      "hospitals",
      "hot-tub-person",
      "hotdog",
      "hotel",
      "hourglass",
      "hourglass-clock",
      "hourglass-empty",
      "hourglass-end",
      "hourglass-start",
      "house",
      "house-blank",
      "house-building",
      "house-chimney",
      "house-chimney-blank",
      "house-chimney-crack",
      "house-chimney-heart",
      "house-chimney-medical",
      "house-chimney-user",
      "house-chimney-window",
      "house-crack",
      "house-day",
      "house-flood",
      "house-heart",
      "house-laptop",
      "house-medical",
      "house-night",
      "house-person-leave",
      "house-person-return",
      "house-signal",
      "house-tree",
      "house-turret",
      "house-user",
      "house-window",
      "hryvnia-sign",
      "hundred-points",
      "hurricane",
      "hyphen",
      "i",
      "i-cursor",
      "ice-cream",
      "ice-skate",
      "icicles",
      "icons",
      "id-badge",
      "id-card",
      "id-card-clip",
      "igloo",
      "image",
      "image-landscape",
      "image-polaroid",
      "image-polaroid-user",
      "image-portrait",
      "image-slash",
      "image-user",
      "images",
      "images-user",
      "inbox",
      "inbox-full",
      "inbox-in",
      "inbox-out",
      "inboxes",
      "indent",
      "indian-rupee-sign",
      "industry",
      "industry-windows",
      "infinity",
      "info",
      "inhaler",
      "input-numeric",
      "input-pipe",
      "input-text",
      "integral",
      "intersection",
      "island-tropical",
      "italic",
      "j",
      "jack-o-lantern",
      "jedi",
      "jet-fighter",
      "joint",
      "joystick",
      "jug",
      "k",
      "kaaba",
      "kazoo",
      "kerning",
      "key",
      "key-skeleton",
      "key-skeleton-left-right",
      "keyboard",
      "keyboard-brightness",
      "keyboard-brightness-low",
      "keyboard-down",
      "keyboard-left",
      "keynote",
      "khanda",
      "kidneys",
      "kip-sign",
      "kit-medical",
      "kite",
      "kiwi-bird",
      "kiwi-fruit",
      "knife",
      "knife-kitchen",
      "l",
      "lacrosse-stick",
      "lacrosse-stick-ball",
      "lambda",
      "lamp",
      "lamp-desk",
      "lamp-floor",
      "lamp-street",
      "landmark",
      "landmark-dome",
      "language",
      "laptop",
      "laptop-arrow-down",
      "laptop-code",
      "laptop-medical",
      "laptop-mobile",
      "laptop-slash",
      "lari-sign",
      "lasso",
      "lasso-sparkles",
      "layer-group",
      "layer-minus",
      "layer-plus",
      "leaf",
      "leaf-heart",
      "leaf-maple",
      "leaf-oak",
      "leafy-green",
      "left",
      "left-from-line",
      "left-long",
      "left-long-to-line",
      "left-right",
      "left-to-line",
      "lemon",
      "less-than",
      "less-than-equal",
      "life-ring",
      "light-ceiling",
      "light-emergency",
      "light-emergency-on",
      "light-switch",
      "light-switch-off",
      "light-switch-on",
      "lightbulb",
      "lightbulb-dollar",
      "lightbulb-exclamation",
      "lightbulb-exclamation-on",
      "lightbulb-on",
      "lightbulb-slash",
      "lights-holiday",
      "line-columns",
      "line-height",
      "link",
      "link-horizontal",
      "link-horizontal-slash",
      "link-simple",
      "link-simple-slash",
      "link-slash",
      "lips",
      "lira-sign",
      "list",
      "list-check",
      "list-dropdown",
      "list-music",
      "list-ol",
      "list-radio",
      "list-timeline",
      "list-tree",
      "list-ul",
      "litecoin-sign",
      "loader",
      "lobster",
      "location-arrow",
      "location-check",
      "location-crosshairs",
      "location-crosshairs-slash",
      "location-dot",
      "location-dot-slash",
      "location-exclamation",
      "location-minus",
      "location-pen",
      "location-pin",
      "location-pin-slash",
      "location-plus",
      "location-question",
      "location-smile",
      "location-xmark",
      "lock",
      "lock-a",
      "lock-hashtag",
      "lock-keyhole",
      "lock-keyhole-open",
      "lock-open",
      "lollipop",
      "loveseat",
      "luchador-mask",
      "lungs",
      "lungs-virus",
      "m",
      "mace",
      "magnet",
      "magnifying-glass",
      "magnifying-glass-dollar",
      "magnifying-glass-location",
      "magnifying-glass-minus",
      "magnifying-glass-plus",
      "mailbox",
      "manat-sign",
      "mandolin",
      "mango",
      "manhole",
      "map",
      "map-location",
      "map-location-dot",
      "map-pin",
      "marker",
      "mars",
      "mars-and-venus",
      "mars-double",
      "mars-stroke",
      "mars-stroke-right",
      "mars-stroke-up",
      "martini-glass",
      "martini-glass-citrus",
      "martini-glass-empty",
      "mask",
      "mask-face",
      "mask-snorkel",
      "masks-theater",
      "maximize",
      "meat",
      "medal",
      "megaphone",
      "melon",
      "melon-slice",
      "memo",
      "memo-circle-check",
      "memo-circle-info",
      "memo-pad",
      "memory",
      "menorah",
      "mercury",
      "message",
      "message-arrow-down",
      "message-arrow-up",
      "message-arrow-up-right",
      "message-bot",
      "message-captions",
      "message-check",
      "message-code",
      "message-dollar",
      "message-dots",
      "message-exclamation",
      "message-image",
      "message-lines",
      "message-medical",
      "message-middle",
      "message-middle-top",
      "message-minus",
      "message-music",
      "message-pen",
      "message-plus",
      "message-question",
      "message-quote",
      "message-slash",
      "message-smile",
      "message-sms",
      "message-text",
      "message-xmark",
      "messages",
      "messages-dollar",
      "messages-question",
      "meteor",
      "meter",
      "meter-bolt",
      "meter-droplet",
      "meter-fire",
      "microchip",
      "microchip-ai",
      "microphone",
      "microphone-lines",
      "microphone-lines-slash",
      "microphone-slash",
      "microphone-stand",
      "microscope",
      "microwave",
      "mill-sign",
      "minimize",
      "minus",
      "mistletoe",
      "mitten",
      "mobile",
      "mobile-button",
      "mobile-notch",
      "mobile-screen",
      "mobile-screen-button",
      "mobile-signal",
      "mobile-signal-out",
      "money-bill",
      "money-bill-1",
      "money-bill-1-wave",
      "money-bill-simple",
      "money-bill-simple-wave",
      "money-bill-wave",
      "money-bills",
      "money-bills-simple",
      "money-check",
      "money-check-dollar",
      "money-check-dollar-pen",
      "money-check-pen",
      "money-from-bracket",
      "money-simple-from-bracket",
      "monitor-waveform",
      "monkey",
      "monument",
      "moon",
      "moon-cloud",
      "moon-over-sun",
      "moon-stars",
      "moped",
      "mortar-pestle",
      "mosque",
      "motorcycle",
      "mountain",
      "mountains",
      "mp3-player",
      "mug",
      "mug-hot",
      "mug-marshmallows",
      "mug-saucer",
      "mug-tea",
      "mug-tea-saucer",
      "mushroom",
      "music",
      "music-note",
      "music-note-slash",
      "music-slash",
      "n",
      "naira-sign",
      "narwhal",
      "nesting-dolls",
      "network-wired",
      "neuter",
      "newspaper",
      "nfc",
      "nfc-lock",
      "nfc-magnifying-glass",
      "nfc-pen",
      "nfc-signal",
      "nfc-slash",
      "nfc-trash",
      "not-equal",
      "notdef",
      "note",
      "note-medical",
      "note-sticky",
      "notebook",
      "notes",
      "notes-medical",
      "o",
      "object-exclude",
      "object-group",
      "object-intersect",
      "object-subtract",
      "object-ungroup",
      "object-union",
      "objects-align-bottom",
      "objects-align-center-horizontal",
      "objects-align-center-vertical",
      "objects-align-left",
      "objects-align-right",
      "objects-align-top",
      "objects-column",
      "octagon",
      "octagon-check",
      "octagon-divide",
      "octagon-exclamation",
      "octagon-minus",
      "octagon-plus",
      "octagon-xmark",
      "oil-can",
      "oil-can-drip",
      "oil-temperature",
      "olive",
      "olive-branch",
      "om",
      "omega",
      "onion",
      "option",
      "ornament",
      "otter",
      "outdent",
      "outlet",
      "oven",
      "overline",
      "p",
      "page",
      "page-caret-down",
      "page-caret-up",
      "pager",
      "paint-roller",
      "paintbrush",
      "paintbrush-fine",
      "paintbrush-pencil",
      "palette",
      "pallet",
      "pallet-box",
      "pallet-boxes",
      "pan-food",
      "pan-frying",
      "pancakes",
      "panel-ews",
      "panel-fire",
      "panorama",
      "paper-plane",
      "paper-plane-top",
      "paperclip",
      "paperclip-vertical",
      "parachute-box",
      "paragraph",
      "paragraph-left",
      "party-bell",
      "party-horn",
      "passport",
      "paste",
      "pause",
      "paw",
      "paw-claws",
      "paw-simple",
      "peace",
      "peach",
      "peanut",
      "peanuts",
      "peapod",
      "pear",
      "pedestal",
      "pegasus",
      "pen",
      "pen-circle",
      "pen-clip",
      "pen-clip-slash",
      "pen-fancy",
      "pen-fancy-slash",
      "pen-field",
      "pen-line",
      "pen-nib",
      "pen-nib-slash",
      "pen-paintbrush",
      "pen-ruler",
      "pen-slash",
      "pen-swirl",
      "pen-to-square",
      "pencil",
      "pencil-slash",
      "people",
      "people-arrows-left-right",
      "people-carry-box",
      "people-dress",
      "people-dress-simple",
      "people-pants",
      "people-pants-simple",
      "people-simple",
      "pepper",
      "pepper-hot",
      "percent",
      "period",
      "person",
      "person-biking",
      "person-biking-mountain",
      "person-booth",
      "person-carry-box",
      "person-digging",
      "person-dolly",
      "person-dolly-empty",
      "person-dots-from-line",
      "person-dress",
      "person-dress-simple",
      "person-from-portal",
      "person-hiking",
      "person-pinball",
      "person-praying",
      "person-pregnant",
      "person-running",
      "person-seat",
      "person-seat-reclined",
      "person-sign",
      "person-simple",
      "person-skating",
      "person-ski-jumping",
      "person-ski-lift",
      "person-skiing",
      "person-skiing-nordic",
      "person-sledding",
      "person-snowboarding",
      "person-snowmobiling",
      "person-swimming",
      "person-to-door",
      "person-to-portal",
      "person-walking",
      "person-walking-with-cane",
      "peseta-sign",
      "peso-sign",
      "phone",
      "phone-arrow-down-left",
      "phone-arrow-up-right",
      "phone-flip",
      "phone-hangup",
      "phone-intercom",
      "phone-missed",
      "phone-office",
      "phone-plus",
      "phone-rotary",
      "phone-slash",
      "phone-volume",
      "phone-xmark",
      "photo-film",
      "photo-film-music",
      "pi",
      "piano",
      "piano-keyboard",
      "pickleball",
      "pie",
      "pig",
      "piggy-bank",
      "pills",
      "pinata",
      "pinball",
      "pineapple",
      "pipe",
      "pipe-circle-check",
      "pipe-collar",
      "pipe-section",
      "pipe-smoking",
      "pipe-valve",
      "pizza",
      "pizza-slice",
      "place-of-worship",
      "plane",
      "plane-arrival",
      "plane-departure",
      "plane-engines",
      "plane-prop",
      "plane-slash",
      "plane-tail",
      "plane-up",
      "plane-up-slash",
      "planet-moon",
      "planet-ringed",
      "plate-utensils",
      "play",
      "play-pause",
      "plug",
      "plus",
      "plus-minus",
      "podcast",
      "podium",
      "podium-star",
      "police-box",
      "poll-people",
      "pompebled",
      "poo",
      "poo-storm",
      "pool-8-ball",
      "poop",
      "popcorn",
      "popsicle",
      "pot-food",
      "potato",
      "power-off",
      "prescription",
      "prescription-bottle",
      "prescription-bottle-medical",
      "presentation-screen",
      "pretzel",
      "print",
      "print-magnifying-glass",
      "print-slash",
      "projector",
      "pump",
      "pump-medical",
      "pump-soap",
      "pumpkin",
      "puzzle",
      "puzzle-piece",
      "puzzle-piece-simple",
      "q",
      "qrcode",
      "question",
      "quote-left",
      "quote-right",
      "quotes",
      "r",
      "rabbit",
      "rabbit-running",
      "racquet",
      "radar",
      "radiation",
      "radio",
      "radio-tuner",
      "rainbow",
      "raindrops",
      "ram",
      "ramp-loading",
      "raygun",
      "receipt",
      "record-vinyl",
      "rectangle",
      "rectangle-ad",
      "rectangle-barcode",
      "rectangle-code",
      "rectangle-history",
      "rectangle-history-circle-plus",
      "rectangle-history-circle-user",
      "rectangle-list",
      "rectangle-pro",
      "rectangle-terminal",
      "rectangle-vertical",
      "rectangle-vertical-history",
      "rectangle-wide",
      "rectangle-xmark",
      "rectangles-mixed",
      "recycle",
      "reel",
      "refrigerator",
      "registered",
      "repeat",
      "repeat-1",
      "reply",
      "reply-all",
      "reply-clock",
      "republican",
      "restroom",
      "restroom-simple",
      "retweet",
      "rhombus",
      "ribbon",
      "right",
      "right-from-bracket",
      "right-from-line",
      "right-left",
      "right-long",
      "right-long-to-line",
      "right-to-bracket",
      "right-to-line",
      "ring",
      "rings-wedding",
      "road",
      "robot",
      "robot-astromech",
      "rocket",
      "rocket-launch",
      "roller-coaster",
      "rotate",
      "rotate-exclamation",
      "rotate-left",
      "rotate-right",
      "route",
      "route-highway",
      "route-interstate",
      "router",
      "rss",
      "ruble-sign",
      "rugby-ball",
      "ruler",
      "ruler-combined",
      "ruler-horizontal",
      "ruler-triangle",
      "ruler-vertical",
      "rupee-sign",
      "rupiah-sign",
      "rv",
      "s",
      "sack",
      "sack-dollar",
      "sailboat",
      "salad",
      "salt-shaker",
      "sandwich",
      "satellite",
      "satellite-dish",
      "sausage",
      "saxophone",
      "saxophone-fire",
      "scale-balanced",
      "scale-unbalanced",
      "scale-unbalanced-flip",
      "scalpel",
      "scalpel-line-dashed",
      "scanner",
      "scanner-gun",
      "scanner-keyboard",
      "scanner-touchscreen",
      "scarecrow",
      "scarf",
      "school",
      "scissors",
      "screen-users",
      "screencast",
      "screwdriver",
      "screwdriver-wrench",
      "scribble",
      "scroll",
      "scroll-old",
      "scroll-torah",
      "scrubber",
      "scythe",
      "sd-card",
      "sd-cards",
      "seal",
      "seal-exclamation",
      "seal-question",
      "seat-airline",
      "section",
      "seedling",
      "semicolon",
      "send-back",
      "send-backward",
      "sensor",
      "sensor-cloud",
      "sensor-fire",
      "sensor-on",
      "sensor-triangle-exclamation",
      "server",
      "shapes",
      "share",
      "share-all",
      "share-from-square",
      "share-nodes",
      "sheep",
      "shekel-sign",
      "shelves",
      "shelves-empty",
      "shield",
      "shield-blank",
      "shield-check",
      "shield-cross",
      "shield-exclamation",
      "shield-keyhole",
      "shield-minus",
      "shield-plus",
      "shield-slash",
      "shield-virus",
      "shield-xmark",
      "ship",
      "shirt",
      "shirt-long-sleeve",
      "shirt-running",
      "shirt-tank-top",
      "shish-kebab",
      "shoe-prints",
      "shop",
      "shop-lock",
      "shop-slash",
      "shovel",
      "shovel-snow",
      "shower",
      "shower-down",
      "shredder",
      "shrimp",
      "shuffle",
      "shutters",
      "shuttle-space",
      "shuttlecock",
      "sickle",
      "sidebar",
      "sidebar-flip",
      "sigma",
      "sign-hanging",
      "signal",
      "signal-bars",
      "signal-bars-fair",
      "signal-bars-good",
      "signal-bars-slash",
      "signal-bars-weak",
      "signal-fair",
      "signal-good",
      "signal-slash",
      "signal-stream",
      "signal-stream-slash",
      "signal-strong",
      "signal-weak",
      "signature",
      "signature-lock",
      "signature-slash",
      "signs-post",
      "sim-card",
      "sim-cards",
      "sink",
      "siren",
      "siren-on",
      "sitemap",
      "skeleton",
      "ski-boot",
      "ski-boot-ski",
      "skull",
      "skull-cow",
      "skull-crossbones",
      "slash",
      "slash-back",
      "slash-forward",
      "sleigh",
      "slider",
      "sliders",
      "sliders-simple",
      "sliders-up",
      "slot-machine",
      "smog",
      "smoke",
      "smoking",
      "snake",
      "snooze",
      "snow-blowing",
      "snowflake",
      "snowflakes",
      "snowman",
      "snowman-head",
      "snowplow",
      "soap",
      "socks",
      "soft-serve",
      "solar-panel",
      "solar-system",
      "sort",
      "sort-down",
      "sort-up",
      "spa",
      "space-station-moon",
      "space-station-moon-construction",
      "spade",
      "spaghetti-monster-flying",
      "sparkles",
      "speaker",
      "speakers",
      "spell-check",
      "spider",
      "spider-black-widow",
      "spider-web",
      "spinner",
      "spinner-third",
      "split",
      "splotch",
      "spoon",
      "sportsball",
      "spray-can",
      "spray-can-sparkles",
      "sprinkler",
      "sprinkler-ceiling",
      "square",
      "square-0",
      "square-1",
      "square-2",
      "square-3",
      "square-4",
      "square-5",
      "square-6",
      "square-7",
      "square-8",
      "square-9",
      "square-a",
      "square-a-lock",
      "square-ampersand",
      "square-arrow-down",
      "square-arrow-down-left",
      "square-arrow-down-right",
      "square-arrow-left",
      "square-arrow-right",
      "square-arrow-up",
      "square-arrow-up-left",
      "square-arrow-up-right",
      "square-b",
      "square-bolt",
      "square-c",
      "square-caret-down",
      "square-caret-left",
      "square-caret-right",
      "square-caret-up",
      "square-check",
      "square-chevron-down",
      "square-chevron-left",
      "square-chevron-right",
      "square-chevron-up",
      "square-code",
      "square-d",
      "square-dashed",
      "square-divide",
      "square-dollar",
      "square-down",
      "square-down-left",
      "square-down-right",
      "square-e",
      "square-ellipsis",
      "square-ellipsis-vertical",
      "square-envelope",
      "square-exclamation",
      "square-f",
      "square-fragile",
      "square-full",
      "square-g",
      "square-h",
      "square-heart",
      "square-i",
      "square-info",
      "square-j",
      "square-k",
      "square-kanban",
      "square-l",
      "square-left",
      "square-list",
      "square-m",
      "square-minus",
      "square-n",
      "square-o",
      "square-p",
      "square-parking",
      "square-parking-slash",
      "square-pen",
      "square-phone",
      "square-phone-flip",
      "square-phone-hangup",
      "square-plus",
      "square-poll-horizontal",
      "square-poll-vertical",
      "square-q",
      "square-quarters",
      "square-question",
      "square-quote",
      "square-r",
      "square-right",
      "square-ring",
      "square-root",
      "square-root-variable",
      "square-rss",
      "square-s",
      "square-share-nodes",
      "square-sliders",
      "square-sliders-vertical",
      "square-small",
      "square-star",
      "square-t",
      "square-terminal",
      "square-this-way-up",
      "square-u",
      "square-up",
      "square-up-left",
      "square-up-right",
      "square-user",
      "square-v",
      "square-w",
      "square-x",
      "square-xmark",
      "square-y",
      "square-z",
      "squid",
      "squirrel",
      "staff",
      "stairs",
      "stamp",
      "standard-definition",
      "star",
      "star-and-crescent",
      "star-christmas",
      "star-exclamation",
      "star-half",
      "star-half-stroke",
      "star-of-david",
      "star-of-life",
      "star-sharp",
      "star-sharp-half",
      "star-sharp-half-stroke",
      "star-shooting",
      "starfighter",
      "starfighter-twin-ion-engine",
      "starfighter-twin-ion-engine-advanced",
      "stars",
      "starship",
      "starship-freighter",
      "steak",
      "steering-wheel",
      "sterling-sign",
      "stethoscope",
      "stocking",
      "stomach",
      "stop",
      "stopwatch",
      "stopwatch-20",
      "store",
      "store-lock",
      "store-slash",
      "strawberry",
      "street-view",
      "stretcher",
      "strikethrough",
      "stroopwafel",
      "subscript",
      "suitcase",
      "suitcase-medical",
      "suitcase-rolling",
      "sun",
      "sun-bright",
      "sun-cloud",
      "sun-dust",
      "sun-haze",
      "sunglasses",
      "sunrise",
      "sunset",
      "superscript",
      "sushi",
      "sushi-roll",
      "swatchbook",
      "sword",
      "sword-laser",
      "sword-laser-alt",
      "swords",
      "swords-laser",
      "symbols",
      "synagogue",
      "syringe",
      "t",
      "table",
      "table-cells",
      "table-cells-large",
      "table-columns",
      "table-layout",
      "table-list",
      "table-picnic",
      "table-pivot",
      "table-rows",
      "table-tennis-paddle-ball",
      "table-tree",
      "tablet",
      "tablet-button",
      "tablet-rugged",
      "tablet-screen",
      "tablet-screen-button",
      "tablets",
      "tachograph-digital",
      "taco",
      "tag",
      "tags",
      "tally",
      "tally-1",
      "tally-2",
      "tally-3",
      "tally-4",
      "tamale",
      "tank-water",
      "tape",
      "taxi",
      "taxi-bus",
      "teddy-bear",
      "teeth",
      "teeth-open",
      "telescope",
      "temperature-arrow-down",
      "temperature-arrow-up",
      "temperature-empty",
      "temperature-full",
      "temperature-half",
      "temperature-high",
      "temperature-list",
      "temperature-low",
      "temperature-quarter",
      "temperature-snow",
      "temperature-sun",
      "temperature-three-quarters",
      "tenge-sign",
      "tennis-ball",
      "terminal",
      "text",
      "text-height",
      "text-size",
      "text-slash",
      "text-width",
      "thermometer",
      "theta",
      "thought-bubble",
      "thumbs-down",
      "thumbs-up",
      "thumbtack",
      "tick",
      "ticket",
      "ticket-airline",
      "ticket-simple",
      "tickets-airline",
      "tilde",
      "timeline",
      "timeline-arrow",
      "timer",
      "tire",
      "tire-flat",
      "tire-pressure-warning",
      "tire-rugged",
      "toggle-off",
      "toggle-on",
      "toilet",
      "toilet-paper",
      "toilet-paper-blank",
      "toilet-paper-blank-under",
      "toilet-paper-slash",
      "toilet-paper-under",
      "toilet-paper-under-slash",
      "tomato",
      "tombstone",
      "tombstone-blank",
      "toolbox",
      "tooth",
      "toothbrush",
      "torii-gate",
      "tornado",
      "tower-broadcast",
      "tower-control",
      "tractor",
      "trademark",
      "traffic-cone",
      "traffic-light",
      "traffic-light-go",
      "traffic-light-slow",
      "traffic-light-stop",
      "trailer",
      "train",
      "train-subway",
      "train-subway-tunnel",
      "train-track",
      "train-tram",
      "train-tunnel",
      "transformer-bolt",
      "transgender",
      "transporter",
      "transporter-1",
      "transporter-2",
      "transporter-3",
      "transporter-4",
      "transporter-5",
      "transporter-6",
      "transporter-7",
      "transporter-empty",
      "trash",
      "trash-arrow-up",
      "trash-can",
      "trash-can-arrow-up",
      "trash-can-check",
      "trash-can-clock",
      "trash-can-list",
      "trash-can-plus",
      "trash-can-slash",
      "trash-can-undo",
      "trash-can-xmark",
      "trash-check",
      "trash-clock",
      "trash-list",
      "trash-plus",
      "trash-slash",
      "trash-undo",
      "trash-xmark",
      "treasure-chest",
      "tree",
      "tree-christmas",
      "tree-deciduous",
      "tree-decorated",
      "tree-large",
      "tree-palm",
      "trees",
      "triangle",
      "triangle-exclamation",
      "triangle-instrument",
      "triangle-person-digging",
      "trophy",
      "trophy-star",
      "truck",
      "truck-bolt",
      "truck-clock",
      "truck-container",
      "truck-container-empty",
      "truck-fast",
      "truck-flatbed",
      "truck-front",
      "truck-medical",
      "truck-monster",
      "truck-moving",
      "truck-pickup",
      "truck-plow",
      "truck-ramp",
      "truck-ramp-box",
      "truck-ramp-couch",
      "truck-tow",
      "trumpet",
      "tty",
      "tty-answer",
      "tugrik-sign",
      "turkey",
      "turkish-lira-sign",
      "turn-down",
      "turn-down-left",
      "turn-down-right",
      "turn-up",
      "turntable",
      "turtle",
      "tv",
      "tv-music",
      "tv-retro",
      "typewriter",
      "u",
      "ufo",
      "ufo-beam",
      "umbrella",
      "umbrella-beach",
      "umbrella-simple",
      "underline",
      "unicorn",
      "uniform-martial-arts",
      "union",
      "universal-access",
      "unlock",
      "unlock-keyhole",
      "up",
      "up-down",
      "up-down-left-right",
      "up-from-dotted-line",
      "up-from-line",
      "up-left",
      "up-long",
      "up-right",
      "up-right-and-down-left-from-center",
      "up-right-from-square",
      "up-to-dotted-line",
      "up-to-line",
      "upload",
      "usb-drive",
      "user",
      "user-alien",
      "user-astronaut",
      "user-bounty-hunter",
      "user-check",
      "user-chef",
      "user-clock",
      "user-cowboy",
      "user-crown",
      "user-doctor",
      "user-doctor-hair",
      "user-doctor-hair-long",
      "user-doctor-message",
      "user-gear",
      "user-graduate",
      "user-group",
      "user-group-crown",
      "user-hair",
      "user-hair-buns",
      "user-hair-long",
      "user-hair-mullet",
      "user-headset",
      "user-helmet-safety",
      "user-injured",
      "user-large",
      "user-large-slash",
      "user-lock",
      "user-minus",
      "user-music",
      "user-ninja",
      "user-nurse",
      "user-nurse-hair",
      "user-nurse-hair-long",
      "user-pen",
      "user-pilot",
      "user-pilot-tie",
      "user-plus",
      "user-police",
      "user-police-tie",
      "user-robot",
      "user-robot-xmarks",
      "user-secret",
      "user-shakespeare",
      "user-shield",
      "user-slash",
      "user-tag",
      "user-tie",
      "user-tie-hair",
      "user-tie-hair-long",
      "user-unlock",
      "user-visor",
      "user-vneck",
      "user-vneck-hair",
      "user-vneck-hair-long",
      "user-xmark",
      "users",
      "users-gear",
      "users-medical",
      "users-slash",
      "utensils",
      "utensils-slash",
      "utility-pole",
      "utility-pole-double",
      "v",
      "vacuum",
      "vacuum-robot",
      "value-absolute",
      "van-shuttle",
      "vault",
      "vector-circle",
      "vector-polygon",
      "vector-square",
      "vent-damper",
      "venus",
      "venus-double",
      "venus-mars",
      "vest",
      "vest-patches",
      "vial",
      "vials",
      "video",
      "video-arrow-down-left",
      "video-arrow-up-right",
      "video-plus",
      "video-slash",
      "vihara",
      "violin",
      "virus",
      "virus-covid",
      "virus-covid-slash",
      "virus-slash",
      "viruses",
      "voicemail",
      "volcano",
      "volleyball",
      "volume",
      "volume-high",
      "volume-low",
      "volume-off",
      "volume-slash",
      "volume-xmark",
      "vr-cardboard",
      "w",
      "waffle",
      "wagon-covered",
      "walker",
      "walkie-talkie",
      "wallet",
      "wand",
      "wand-magic",
      "wand-magic-sparkles",
      "wand-sparkles",
      "warehouse",
      "warehouse-full",
      "washing-machine",
      "watch",
      "watch-apple",
      "watch-calculator",
      "watch-fitness",
      "watch-smart",
      "water",
      "water-arrow-down",
      "water-arrow-up",
      "water-ladder",
      "watermelon-slice",
      "wave-pulse",
      "wave-sine",
      "wave-square",
      "wave-triangle",
      "waveform",
      "waveform-lines",
      "weight-hanging",
      "weight-scale",
      "whale",
      "wheat",
      "wheat-awn",
      "wheat-awn-slash",
      "wheat-slash",
      "wheelchair",
      "wheelchair-move",
      "whiskey-glass",
      "whiskey-glass-ice",
      "whistle",
      "wifi",
      "wifi-exclamation",
      "wifi-fair",
      "wifi-slash",
      "wifi-weak",
      "wind",
      "wind-turbine",
      "wind-warning",
      "window",
      "window-flip",
      "window-frame",
      "window-frame-open",
      "window-maximize",
      "window-minimize",
      "window-restore",
      "windsock",
      "wine-bottle",
      "wine-glass",
      "wine-glass-crack",
      "wine-glass-empty",
      "won-sign",
      "wreath",
      "wrench",
      "wrench-simple",
      "x",
      "x-ray",
      "xmark",
      "xmark-to-slot",
      "y",
      "yen-sign",
      "yin-yang",
      "z"
    ],
    light: [],
    solid: [],
    thin: [],
    duotone: [
      "abacus",
      "acorn",
      "ad",
      "address-book",
      "address-card",
      "adjust",
      "air-conditioner",
      "air-freshener",
      "alarm-clock",
      "alarm-exclamation",
      "alarm-plus",
      "alarm-snooze",
      "album",
      "album-collection",
      "alicorn",
      "alien",
      "alien-monster",
      "align-center",
      "align-justify",
      "align-left",
      "align-right",
      "align-slash",
      "allergies",
      "ambulance",
      "american-sign-language-interpreting",
      "amp-guitar",
      "analytics",
      "anchor",
      "angel",
      "angle-double-down",
      "angle-double-left",
      "angle-double-right",
      "angle-double-up",
      "angle-down",
      "angle-left",
      "angle-right",
      "angle-up",
      "angry",
      "ankh",
      "apple-alt",
      "apple-crate",
      "archive",
      "archway",
      "arrow-alt-circle-down",
      "arrow-alt-circle-left",
      "arrow-alt-circle-right",
      "arrow-alt-circle-up",
      "arrow-alt-down",
      "arrow-alt-from-bottom",
      "arrow-alt-from-left",
      "arrow-alt-from-right",
      "arrow-alt-from-top",
      "arrow-alt-left",
      "arrow-alt-right",
      "arrow-alt-square-down",
      "arrow-alt-square-left",
      "arrow-alt-square-right",
      "arrow-alt-square-up",
      "arrow-alt-to-bottom",
      "arrow-alt-to-left",
      "arrow-alt-to-right",
      "arrow-alt-to-top",
      "arrow-alt-up",
      "arrow-circle-down",
      "arrow-circle-left",
      "arrow-circle-right",
      "arrow-circle-up",
      "arrow-down",
      "arrow-from-bottom",
      "arrow-from-left",
      "arrow-from-right",
      "arrow-from-top",
      "arrow-left",
      "arrow-right",
      "arrow-square-down",
      "arrow-square-left",
      "arrow-square-right",
      "arrow-square-up",
      "arrow-to-bottom",
      "arrow-to-left",
      "arrow-to-right",
      "arrow-to-top",
      "arrow-up",
      "arrows",
      "arrows-alt",
      "arrows-alt-h",
      "arrows-alt-v",
      "arrows-h",
      "arrows-v",
      "assistive-listening-systems",
      "asterisk",
      "at",
      "atlas",
      "atom",
      "atom-alt",
      "audio-description",
      "award",
      "axe",
      "axe-battle",
      "baby",
      "baby-carriage",
      "backpack",
      "backspace",
      "backward",
      "bacon",
      "bacteria",
      "bacterium",
      "badge",
      "badge-check",
      "badge-dollar",
      "badge-percent",
      "badge-sheriff",
      "badger-honey",
      "bags-shopping",
      "bahai",
      "balance-scale",
      "balance-scale-left",
      "balance-scale-right",
      "ball-pile",
      "ballot",
      "ballot-check",
      "ban",
      "band-aid",
      "banjo",
      "barcode",
      "barcode-alt",
      "barcode-read",
      "barcode-scan",
      "bars",
      "baseball",
      "baseball-ball",
      "basketball-ball",
      "basketball-hoop",
      "bat",
      "bath",
      "battery-bolt",
      "battery-empty",
      "battery-full",
      "battery-half",
      "battery-quarter",
      "battery-slash",
      "battery-three-quarters",
      "bed",
      "bed-alt",
      "bed-bunk",
      "bed-empty",
      "beer",
      "bell",
      "bell-exclamation",
      "bell-on",
      "bell-plus",
      "bell-school",
      "bell-school-slash",
      "bell-slash",
      "bells",
      "betamax",
      "bezier-curve",
      "bible",
      "bicycle",
      "biking",
      "biking-mountain",
      "binoculars",
      "biohazard",
      "birthday-cake",
      "blanket",
      "blender",
      "blender-phone",
      "blind",
      "blinds",
      "blinds-open",
      "blinds-raised",
      "blog",
      "bold",
      "bolt",
      "bomb",
      "bone",
      "bone-break",
      "bong",
      "book",
      "book-alt",
      "book-dead",
      "book-heart",
      "book-medical",
      "book-open",
      "book-reader",
      "book-spells",
      "book-user",
      "bookmark",
      "books",
      "books-medical",
      "boombox",
      "boot",
      "booth-curtain",
      "border-all",
      "border-bottom",
      "border-center-h",
      "border-center-v",
      "border-inner",
      "border-left",
      "border-none",
      "border-outer",
      "border-right",
      "border-style",
      "border-style-alt",
      "border-top",
      "bow-arrow",
      "bowling-ball",
      "bowling-pins",
      "box",
      "box-alt",
      "box-ballot",
      "box-check",
      "box-fragile",
      "box-full",
      "box-heart",
      "box-open",
      "box-tissue",
      "box-up",
      "box-usd",
      "boxes",
      "boxes-alt",
      "boxing-glove",
      "brackets",
      "brackets-curly",
      "braille",
      "brain",
      "bread-loaf",
      "bread-slice",
      "briefcase",
      "briefcase-medical",
      "bring-forward",
      "bring-front",
      "broadcast-tower",
      "broom",
      "browser",
      "brush",
      "bug",
      "building",
      "bullhorn",
      "bullseye",
      "bullseye-arrow",
      "bullseye-pointer",
      "burger-soda",
      "burn",
      "burrito",
      "bus",
      "bus-alt",
      "bus-school",
      "business-time",
      "cabinet-filing",
      "cactus",
      "calculator",
      "calculator-alt",
      "calendar",
      "calendar-alt",
      "calendar-check",
      "calendar-day",
      "calendar-edit",
      "calendar-exclamation",
      "calendar-minus",
      "calendar-plus",
      "calendar-star",
      "calendar-times",
      "calendar-week",
      "camcorder",
      "camera",
      "camera-alt",
      "camera-home",
      "camera-movie",
      "camera-polaroid",
      "camera-retro",
      "campfire",
      "campground",
      "candle-holder",
      "candy-cane",
      "candy-corn",
      "cannabis",
      "capsules",
      "car",
      "car-alt",
      "car-battery",
      "car-building",
      "car-bump",
      "car-bus",
      "car-crash",
      "car-garage",
      "car-mechanic",
      "car-side",
      "car-tilt",
      "car-wash",
      "caravan",
      "caravan-alt",
      "caret-circle-down",
      "caret-circle-left",
      "caret-circle-right",
      "caret-circle-up",
      "caret-down",
      "caret-left",
      "caret-right",
      "caret-square-down",
      "caret-square-left",
      "caret-square-right",
      "caret-square-up",
      "caret-up",
      "carrot",
      "cars",
      "cart-arrow-down",
      "cart-plus",
      "cash-register",
      "cassette-tape",
      "cat",
      "cat-space",
      "cauldron",
      "cctv",
      "certificate",
      "chair",
      "chair-office",
      "chalkboard",
      "chalkboard-teacher",
      "charging-station",
      "chart-area",
      "chart-bar",
      "chart-line",
      "chart-line-down",
      "chart-network",
      "chart-pie",
      "chart-pie-alt",
      "chart-scatter",
      "check",
      "check-circle",
      "check-double",
      "check-square",
      "cheese",
      "cheese-swiss",
      "cheeseburger",
      "chess",
      "chess-bishop",
      "chess-bishop-alt",
      "chess-board",
      "chess-clock",
      "chess-clock-alt",
      "chess-king",
      "chess-king-alt",
      "chess-knight",
      "chess-knight-alt",
      "chess-pawn",
      "chess-pawn-alt",
      "chess-queen",
      "chess-queen-alt",
      "chess-rook",
      "chess-rook-alt",
      "chevron-circle-down",
      "chevron-circle-left",
      "chevron-circle-right",
      "chevron-circle-up",
      "chevron-double-down",
      "chevron-double-left",
      "chevron-double-right",
      "chevron-double-up",
      "chevron-down",
      "chevron-left",
      "chevron-right",
      "chevron-square-down",
      "chevron-square-left",
      "chevron-square-right",
      "chevron-square-up",
      "chevron-up",
      "child",
      "chimney",
      "church",
      "circle",
      "circle-notch",
      "city",
      "clarinet",
      "claw-marks",
      "clinic-medical",
      "clipboard",
      "clipboard-check",
      "clipboard-list",
      "clipboard-list-check",
      "clipboard-prescription",
      "clipboard-user",
      "clock",
      "clone",
      "closed-captioning",
      "cloud",
      "cloud-download",
      "cloud-download-alt",
      "cloud-drizzle",
      "cloud-hail",
      "cloud-hail-mixed",
      "cloud-meatball",
      "cloud-moon",
      "cloud-moon-rain",
      "cloud-music",
      "cloud-rain",
      "cloud-rainbow",
      "cloud-showers",
      "cloud-showers-heavy",
      "cloud-sleet",
      "cloud-snow",
      "cloud-sun",
      "cloud-sun-rain",
      "cloud-upload",
      "cloud-upload-alt",
      "clouds",
      "clouds-moon",
      "clouds-sun",
      "club",
      "cocktail",
      "code",
      "code-branch",
      "code-commit",
      "code-merge",
      "coffee",
      "coffee-pot",
      "coffee-togo",
      "coffin",
      "coffin-cross",
      "cog",
      "cogs",
      "coin",
      "coins",
      "columns",
      "comet",
      "comment",
      "comment-alt",
      "comment-alt-check",
      "comment-alt-dollar",
      "comment-alt-dots",
      "comment-alt-edit",
      "comment-alt-exclamation",
      "comment-alt-lines",
      "comment-alt-medical",
      "comment-alt-minus",
      "comment-alt-music",
      "comment-alt-plus",
      "comment-alt-slash",
      "comment-alt-smile",
      "comment-alt-times",
      "comment-check",
      "comment-dollar",
      "comment-dots",
      "comment-edit",
      "comment-exclamation",
      "comment-lines",
      "comment-medical",
      "comment-minus",
      "comment-music",
      "comment-plus",
      "comment-slash",
      "comment-smile",
      "comment-times",
      "comments",
      "comments-alt",
      "comments-alt-dollar",
      "comments-dollar",
      "compact-disc",
      "compass",
      "compass-slash",
      "compress",
      "compress-alt",
      "compress-arrows-alt",
      "compress-wide",
      "computer-classic",
      "computer-speaker",
      "concierge-bell",
      "construction",
      "container-storage",
      "conveyor-belt",
      "conveyor-belt-alt",
      "cookie",
      "cookie-bite",
      "copy",
      "copyright",
      "corn",
      "couch",
      "cow",
      "cowbell",
      "cowbell-more",
      "credit-card",
      "credit-card-blank",
      "credit-card-front",
      "cricket",
      "croissant",
      "crop",
      "crop-alt",
      "cross",
      "crosshairs",
      "crow",
      "crown",
      "crutch",
      "crutches",
      "cube",
      "cubes",
      "curling",
      "cut",
      "dagger",
      "database",
      "deaf",
      "debug",
      "deer",
      "deer-rudolph",
      "democrat",
      "desktop",
      "desktop-alt",
      "dewpoint",
      "dharmachakra",
      "diagnoses",
      "diamond",
      "dice",
      "dice-d10",
      "dice-d12",
      "dice-d20",
      "dice-d4",
      "dice-d6",
      "dice-d8",
      "dice-five",
      "dice-four",
      "dice-one",
      "dice-six",
      "dice-three",
      "dice-two",
      "digging",
      "digital-tachograph",
      "diploma",
      "directions",
      "disc-drive",
      "disease",
      "divide",
      "dizzy",
      "dna",
      "do-not-enter",
      "dog",
      "dog-leashed",
      "dollar-sign",
      "dolly",
      "dolly-empty",
      "dolly-flatbed",
      "dolly-flatbed-alt",
      "dolly-flatbed-empty",
      "donate",
      "door-closed",
      "door-open",
      "dot-circle",
      "dove",
      "download",
      "drafting-compass",
      "dragon",
      "draw-circle",
      "draw-polygon",
      "draw-square",
      "dreidel",
      "drone",
      "drone-alt",
      "drum",
      "drum-steelpan",
      "drumstick",
      "drumstick-bite",
      "dryer",
      "dryer-alt",
      "duck",
      "dumbbell",
      "dumpster",
      "dumpster-fire",
      "dungeon",
      "ear",
      "ear-muffs",
      "eclipse",
      "eclipse-alt",
      "edit",
      "egg",
      "egg-fried",
      "eject",
      "elephant",
      "ellipsis-h",
      "ellipsis-h-alt",
      "ellipsis-v",
      "ellipsis-v-alt",
      "empty-set",
      "engine-warning",
      "envelope",
      "envelope-open",
      "envelope-open-dollar",
      "envelope-open-text",
      "envelope-square",
      "equals",
      "eraser",
      "ethernet",
      "euro-sign",
      "exchange",
      "exchange-alt",
      "exclamation",
      "exclamation-circle",
      "exclamation-square",
      "exclamation-triangle",
      "expand",
      "expand-alt",
      "expand-arrows",
      "expand-arrows-alt",
      "expand-wide",
      "external-link",
      "external-link-alt",
      "external-link-square",
      "external-link-square-alt",
      "eye",
      "eye-dropper",
      "eye-evil",
      "eye-slash",
      "fan",
      "fan-table",
      "farm",
      "fast-backward",
      "fast-forward",
      "faucet",
      "faucet-drip",
      "fax",
      "feather",
      "feather-alt",
      "female",
      "field-hockey",
      "fighter-jet",
      "file",
      "file-alt",
      "file-archive",
      "file-audio",
      "file-certificate",
      "file-chart-line",
      "file-chart-pie",
      "file-check",
      "file-code",
      "file-contract",
      "file-csv",
      "file-download",
      "file-edit",
      "file-excel",
      "file-exclamation",
      "file-export",
      "file-image",
      "file-import",
      "file-invoice",
      "file-invoice-dollar",
      "file-medical",
      "file-medical-alt",
      "file-minus",
      "file-music",
      "file-pdf",
      "file-plus",
      "file-powerpoint",
      "file-prescription",
      "file-search",
      "file-signature",
      "file-spreadsheet",
      "file-times",
      "file-upload",
      "file-user",
      "file-video",
      "file-word",
      "files-medical",
      "fill",
      "fill-drip",
      "film",
      "film-alt",
      "film-canister",
      "filter",
      "fingerprint",
      "fire",
      "fire-alt",
      "fire-extinguisher",
      "fire-smoke",
      "fireplace",
      "first-aid",
      "fish",
      "fish-cooked",
      "fist-raised",
      "flag",
      "flag-alt",
      "flag-checkered",
      "flag-usa",
      "flame",
      "flashlight",
      "flask",
      "flask-poison",
      "flask-potion",
      "flower",
      "flower-daffodil",
      "flower-tulip",
      "flushed",
      "flute",
      "flux-capacitor",
      "fog",
      "folder",
      "folder-download",
      "folder-minus",
      "folder-open",
      "folder-plus",
      "folder-times",
      "folder-tree",
      "folder-upload",
      "folders",
      "font",
      "font-case",
      "football-ball",
      "football-helmet",
      "forklift",
      "forward",
      "fragile",
      "french-fries",
      "frog",
      "frosty-head",
      "frown",
      "frown-open",
      "function",
      "funnel-dollar",
      "futbol",
      "galaxy",
      "game-board",
      "game-board-alt",
      "game-console-handheld",
      "gamepad",
      "gamepad-alt",
      "garage",
      "garage-car",
      "garage-open",
      "gas-pump",
      "gas-pump-slash",
      "gavel",
      "gem",
      "genderless",
      "ghost",
      "gift",
      "gift-card",
      "gifts",
      "gingerbread-man",
      "glass",
      "glass-champagne",
      "glass-cheers",
      "glass-citrus",
      "glass-martini",
      "glass-martini-alt",
      "glass-whiskey",
      "glass-whiskey-rocks",
      "glasses",
      "glasses-alt",
      "globe",
      "globe-africa",
      "globe-americas",
      "globe-asia",
      "globe-europe",
      "globe-snow",
      "globe-stand",
      "golf-ball",
      "golf-club",
      "gopuram",
      "graduation-cap",
      "gramophone",
      "greater-than",
      "greater-than-equal",
      "grimace",
      "grin",
      "grin-alt",
      "grin-beam",
      "grin-beam-sweat",
      "grin-hearts",
      "grin-squint",
      "grin-squint-tears",
      "grin-stars",
      "grin-tears",
      "grin-tongue",
      "grin-tongue-squint",
      "grin-tongue-wink",
      "grin-wink",
      "grip-horizontal",
      "grip-lines",
      "grip-lines-vertical",
      "grip-vertical",
      "guitar",
      "guitar-electric",
      "guitars",
      "h-square",
      "h1",
      "h2",
      "h3",
      "h4",
      "hamburger",
      "hammer",
      "hammer-war",
      "hamsa",
      "hand-heart",
      "hand-holding",
      "hand-holding-box",
      "hand-holding-heart",
      "hand-holding-magic",
      "hand-holding-medical",
      "hand-holding-seedling",
      "hand-holding-usd",
      "hand-holding-water",
      "hand-lizard",
      "hand-middle-finger",
      "hand-paper",
      "hand-peace",
      "hand-point-down",
      "hand-point-left",
      "hand-point-right",
      "hand-point-up",
      "hand-pointer",
      "hand-receiving",
      "hand-rock",
      "hand-scissors",
      "hand-sparkles",
      "hand-spock",
      "hands",
      "hands-heart",
      "hands-helping",
      "hands-usd",
      "hands-wash",
      "handshake",
      "handshake-alt",
      "handshake-alt-slash",
      "handshake-slash",
      "hanukiah",
      "hard-hat",
      "hashtag",
      "hat-chef",
      "hat-cowboy",
      "hat-cowboy-side",
      "hat-santa",
      "hat-winter",
      "hat-witch",
      "hat-wizard",
      "hdd",
      "head-side",
      "head-side-brain",
      "head-side-cough",
      "head-side-cough-slash",
      "head-side-headphones",
      "head-side-mask",
      "head-side-medical",
      "head-side-virus",
      "head-vr",
      "heading",
      "headphones",
      "headphones-alt",
      "headset",
      "heart",
      "heart-broken",
      "heart-circle",
      "heart-rate",
      "heart-square",
      "heartbeat",
      "heat",
      "helicopter",
      "helmet-battle",
      "hexagon",
      "highlighter",
      "hiking",
      "hippo",
      "history",
      "hockey-mask",
      "hockey-puck",
      "hockey-sticks",
      "holly-berry",
      "home",
      "home-alt",
      "home-heart",
      "home-lg",
      "home-lg-alt",
      "hood-cloak",
      "horizontal-rule",
      "horse",
      "horse-head",
      "horse-saddle",
      "hospital",
      "hospital-alt",
      "hospital-symbol",
      "hospital-user",
      "hospitals",
      "hot-tub",
      "hotdog",
      "hotel",
      "hourglass",
      "hourglass-end",
      "hourglass-half",
      "hourglass-start",
      "house",
      "house-damage",
      "house-day",
      "house-flood",
      "house-leave",
      "house-night",
      "house-return",
      "house-signal",
      "house-user",
      "hryvnia",
      "humidity",
      "hurricane",
      "i-cursor",
      "ice-cream",
      "ice-skate",
      "icicles",
      "icons",
      "icons-alt",
      "id-badge",
      "id-card",
      "id-card-alt",
      "igloo",
      "image",
      "image-polaroid",
      "images",
      "inbox",
      "inbox-in",
      "inbox-out",
      "indent",
      "industry",
      "industry-alt",
      "infinity",
      "info",
      "info-circle",
      "info-square",
      "inhaler",
      "integral",
      "intersection",
      "inventory",
      "island-tropical",
      "italic",
      "jack-o-lantern",
      "jedi",
      "joint",
      "journal-whills",
      "joystick",
      "jug",
      "kaaba",
      "kazoo",
      "kerning",
      "key",
      "key-skeleton",
      "keyboard",
      "keynote",
      "khanda",
      "kidneys",
      "kiss",
      "kiss-beam",
      "kiss-wink-heart",
      "kite",
      "kiwi-bird",
      "knife-kitchen",
      "lambda",
      "lamp",
      "lamp-desk",
      "lamp-floor",
      "landmark",
      "landmark-alt",
      "language",
      "laptop",
      "laptop-code",
      "laptop-house",
      "laptop-medical",
      "lasso",
      "laugh",
      "laugh-beam",
      "laugh-squint",
      "laugh-wink",
      "layer-group",
      "layer-minus",
      "layer-plus",
      "leaf",
      "leaf-heart",
      "leaf-maple",
      "leaf-oak",
      "lemon",
      "less-than",
      "less-than-equal",
      "level-down",
      "level-down-alt",
      "level-up",
      "level-up-alt",
      "life-ring",
      "light-ceiling",
      "light-switch",
      "light-switch-off",
      "light-switch-on",
      "lightbulb",
      "lightbulb-dollar",
      "lightbulb-exclamation",
      "lightbulb-on",
      "lightbulb-slash",
      "lights-holiday",
      "line-columns",
      "line-height",
      "link",
      "lips",
      "lira-sign",
      "list",
      "list-alt",
      "list-music",
      "list-ol",
      "list-ul",
      "location",
      "location-arrow",
      "location-circle",
      "location-slash",
      "lock",
      "lock-alt",
      "lock-open",
      "lock-open-alt",
      "long-arrow-alt-down",
      "long-arrow-alt-left",
      "long-arrow-alt-right",
      "long-arrow-alt-up",
      "long-arrow-down",
      "long-arrow-left",
      "long-arrow-right",
      "long-arrow-up",
      "loveseat",
      "low-vision",
      "luchador",
      "luggage-cart",
      "lungs",
      "lungs-virus",
      "mace",
      "magic",
      "magnet",
      "mail-bulk",
      "mailbox",
      "male",
      "mandolin",
      "map",
      "map-marked",
      "map-marked-alt",
      "map-marker",
      "map-marker-alt",
      "map-marker-alt-slash",
      "map-marker-check",
      "map-marker-edit",
      "map-marker-exclamation",
      "map-marker-minus",
      "map-marker-plus",
      "map-marker-question",
      "map-marker-slash",
      "map-marker-smile",
      "map-marker-times",
      "map-pin",
      "map-signs",
      "marker",
      "mars",
      "mars-double",
      "mars-stroke",
      "mars-stroke-h",
      "mars-stroke-v",
      "mask",
      "meat",
      "medal",
      "medkit",
      "megaphone",
      "meh",
      "meh-blank",
      "meh-rolling-eyes",
      "memory",
      "menorah",
      "mercury",
      "meteor",
      "microchip",
      "microphone",
      "microphone-alt",
      "microphone-alt-slash",
      "microphone-slash",
      "microphone-stand",
      "microscope",
      "microwave",
      "mind-share",
      "minus",
      "minus-circle",
      "minus-hexagon",
      "minus-octagon",
      "minus-square",
      "mistletoe",
      "mitten",
      "mobile",
      "mobile-alt",
      "mobile-android",
      "mobile-android-alt",
      "money-bill",
      "money-bill-alt",
      "money-bill-wave",
      "money-bill-wave-alt",
      "money-check",
      "money-check-alt",
      "money-check-edit",
      "money-check-edit-alt",
      "monitor-heart-rate",
      "monkey",
      "monument",
      "moon",
      "moon-cloud",
      "moon-stars",
      "mortar-pestle",
      "mosque",
      "motorcycle",
      "mountain",
      "mountains",
      "mouse",
      "mouse-alt",
      "mouse-pointer",
      "mp3-player",
      "mug",
      "mug-hot",
      "mug-marshmallows",
      "mug-tea",
      "music",
      "music-alt",
      "music-alt-slash",
      "music-slash",
      "narwhal",
      "network-wired",
      "neuter",
      "newspaper",
      "not-equal",
      "notes-medical",
      "object-group",
      "object-ungroup",
      "octagon",
      "oil-can",
      "oil-temp",
      "om",
      "omega",
      "ornament",
      "otter",
      "outdent",
      "outlet",
      "oven",
      "overline",
      "page-break",
      "pager",
      "paint-brush",
      "paint-brush-alt",
      "paint-roller",
      "palette",
      "pallet",
      "pallet-alt",
      "paper-plane",
      "paperclip",
      "parachute-box",
      "paragraph",
      "paragraph-rtl",
      "parking",
      "parking-circle",
      "parking-circle-slash",
      "parking-slash",
      "passport",
      "pastafarianism",
      "paste",
      "pause",
      "pause-circle",
      "paw",
      "paw-alt",
      "paw-claws",
      "peace",
      "pegasus",
      "pen",
      "pen-alt",
      "pen-fancy",
      "pen-nib",
      "pen-square",
      "pencil",
      "pencil-alt",
      "pencil-paintbrush",
      "pencil-ruler",
      "pennant",
      "people-arrows",
      "people-carry",
      "pepper-hot",
      "percent",
      "percentage",
      "person-booth",
      "person-carry",
      "person-dolly",
      "person-dolly-empty",
      "person-sign",
      "phone",
      "phone-alt",
      "phone-laptop",
      "phone-office",
      "phone-plus",
      "phone-rotary",
      "phone-slash",
      "phone-square",
      "phone-square-alt",
      "phone-volume",
      "photo-video",
      "pi",
      "piano",
      "piano-keyboard",
      "pie",
      "pig",
      "piggy-bank",
      "pills",
      "pizza",
      "pizza-slice",
      "place-of-worship",
      "plane",
      "plane-alt",
      "plane-arrival",
      "plane-departure",
      "plane-slash",
      "planet-moon",
      "planet-ringed",
      "play",
      "play-circle",
      "plug",
      "plus",
      "plus-circle",
      "plus-hexagon",
      "plus-octagon",
      "plus-square",
      "podcast",
      "podium",
      "podium-star",
      "police-box",
      "poll",
      "poll-h",
      "poll-people",
      "poo",
      "poo-storm",
      "poop",
      "popcorn",
      "portal-enter",
      "portal-exit",
      "portrait",
      "pound-sign",
      "power-off",
      "pray",
      "praying-hands",
      "prescription",
      "prescription-bottle",
      "prescription-bottle-alt",
      "presentation",
      "print",
      "print-search",
      "print-slash",
      "procedures",
      "project-diagram",
      "projector",
      "pump-medical",
      "pump-soap",
      "pumpkin",
      "puzzle-piece",
      "qrcode",
      "question",
      "question-circle",
      "question-square",
      "quidditch",
      "quote-left",
      "quote-right",
      "quran",
      "rabbit",
      "rabbit-fast",
      "racquet",
      "radar",
      "radiation",
      "radiation-alt",
      "radio",
      "radio-alt",
      "rainbow",
      "raindrops",
      "ram",
      "ramp-loading",
      "random",
      "raygun",
      "receipt",
      "record-vinyl",
      "rectangle-landscape",
      "rectangle-portrait",
      "rectangle-wide",
      "recycle",
      "redo",
      "redo-alt",
      "refrigerator",
      "registered",
      "remove-format",
      "repeat",
      "repeat-1",
      "repeat-1-alt",
      "repeat-alt",
      "reply",
      "reply-all",
      "republican",
      "restroom",
      "retweet",
      "retweet-alt",
      "ribbon",
      "ring",
      "rings-wedding",
      "road",
      "robot",
      "rocket",
      "rocket-launch",
      "route",
      "route-highway",
      "route-interstate",
      "router",
      "rss",
      "rss-square",
      "ruble-sign",
      "ruler",
      "ruler-combined",
      "ruler-horizontal",
      "ruler-triangle",
      "ruler-vertical",
      "running",
      "rupee-sign",
      "rv",
      "sack",
      "sack-dollar",
      "sad-cry",
      "sad-tear",
      "salad",
      "sandwich",
      "satellite",
      "satellite-dish",
      "sausage",
      "save",
      "sax-hot",
      "saxophone",
      "scalpel",
      "scalpel-path",
      "scanner",
      "scanner-image",
      "scanner-keyboard",
      "scanner-touchscreen",
      "scarecrow",
      "scarf",
      "school",
      "screwdriver",
      "scroll",
      "scroll-old",
      "scrubber",
      "scythe",
      "sd-card",
      "search",
      "search-dollar",
      "search-location",
      "search-minus",
      "search-plus",
      "seedling",
      "send-back",
      "send-backward",
      "sensor",
      "sensor-alert",
      "sensor-fire",
      "sensor-on",
      "sensor-smoke",
      "server",
      "shapes",
      "share",
      "share-all",
      "share-alt",
      "share-alt-square",
      "share-square",
      "sheep",
      "shekel-sign",
      "shield",
      "shield-alt",
      "shield-check",
      "shield-cross",
      "shield-virus",
      "ship",
      "shipping-fast",
      "shipping-timed",
      "shish-kebab",
      "shoe-prints",
      "shopping-bag",
      "shopping-basket",
      "shopping-cart",
      "shovel",
      "shovel-snow",
      "shower",
      "shredder",
      "shuttle-van",
      "shuttlecock",
      "sickle",
      "sigma",
      "sign",
      "sign-in",
      "sign-in-alt",
      "sign-language",
      "sign-out",
      "sign-out-alt",
      "signal",
      "signal-1",
      "signal-2",
      "signal-3",
      "signal-4",
      "signal-alt",
      "signal-alt-1",
      "signal-alt-2",
      "signal-alt-3",
      "signal-alt-slash",
      "signal-slash",
      "signal-stream",
      "signature",
      "sim-card",
      "sink",
      "siren",
      "siren-on",
      "sitemap",
      "skating",
      "skeleton",
      "ski-jump",
      "ski-lift",
      "skiing",
      "skiing-nordic",
      "skull",
      "skull-cow",
      "skull-crossbones",
      "slash",
      "sledding",
      "sleigh",
      "sliders-h",
      "sliders-h-square",
      "sliders-v",
      "sliders-v-square",
      "smile",
      "smile-beam",
      "smile-plus",
      "smile-wink",
      "smog",
      "smoke",
      "smoking",
      "smoking-ban",
      "sms",
      "snake",
      "snooze",
      "snow-blowing",
      "snowboarding",
      "snowflake",
      "snowflakes",
      "snowman",
      "snowmobile",
      "snowplow",
      "soap",
      "socks",
      "solar-panel",
      "solar-system",
      "sort",
      "sort-alpha-down",
      "sort-alpha-down-alt",
      "sort-alpha-up",
      "sort-alpha-up-alt",
      "sort-alt",
      "sort-amount-down",
      "sort-amount-down-alt",
      "sort-amount-up",
      "sort-amount-up-alt",
      "sort-circle",
      "sort-circle-down",
      "sort-circle-up",
      "sort-down",
      "sort-numeric-down",
      "sort-numeric-down-alt",
      "sort-numeric-up",
      "sort-numeric-up-alt",
      "sort-shapes-down",
      "sort-shapes-down-alt",
      "sort-shapes-up",
      "sort-shapes-up-alt",
      "sort-size-down",
      "sort-size-down-alt",
      "sort-size-up",
      "sort-size-up-alt",
      "sort-up",
      "soup",
      "spa",
      "space-shuttle",
      "space-station-moon",
      "space-station-moon-alt",
      "spade",
      "sparkles",
      "speaker",
      "speakers",
      "spell-check",
      "spider",
      "spider-black-widow",
      "spider-web",
      "spinner",
      "spinner-third",
      "splotch",
      "spray-can",
      "sprinkler",
      "square",
      "square-full",
      "square-root",
      "square-root-alt",
      "squirrel",
      "staff",
      "stamp",
      "star",
      "star-and-crescent",
      "star-christmas",
      "star-exclamation",
      "star-half",
      "star-half-alt",
      "star-of-david",
      "star-of-life",
      "star-shooting",
      "starfighter",
      "starfighter-alt",
      "stars",
      "starship",
      "starship-freighter",
      "steak",
      "steering-wheel",
      "step-backward",
      "step-forward",
      "stethoscope",
      "sticky-note",
      "stocking",
      "stomach",
      "stop",
      "stop-circle",
      "stopwatch",
      "stopwatch-20",
      "store",
      "store-alt",
      "store-alt-slash",
      "store-slash",
      "stream",
      "street-view",
      "stretcher",
      "strikethrough",
      "stroopwafel",
      "subscript",
      "subway",
      "suitcase",
      "suitcase-rolling",
      "sun",
      "sun-cloud",
      "sun-dust",
      "sun-haze",
      "sunglasses",
      "sunrise",
      "sunset",
      "superscript",
      "surprise",
      "swatchbook",
      "swimmer",
      "swimming-pool",
      "sword",
      "sword-laser",
      "sword-laser-alt",
      "swords",
      "swords-laser",
      "synagogue",
      "sync",
      "sync-alt",
      "syringe",
      "table",
      "table-tennis",
      "tablet",
      "tablet-alt",
      "tablet-android",
      "tablet-android-alt",
      "tablet-rugged",
      "tablets",
      "tachometer",
      "tachometer-alt",
      "tachometer-alt-average",
      "tachometer-alt-fast",
      "tachometer-alt-fastest",
      "tachometer-alt-slow",
      "tachometer-alt-slowest",
      "tachometer-average",
      "tachometer-fast",
      "tachometer-fastest",
      "tachometer-slow",
      "tachometer-slowest",
      "taco",
      "tag",
      "tags",
      "tally",
      "tanakh",
      "tape",
      "tasks",
      "tasks-alt",
      "taxi",
      "teeth",
      "teeth-open",
      "telescope",
      "temperature-down",
      "temperature-frigid",
      "temperature-high",
      "temperature-hot",
      "temperature-low",
      "temperature-up",
      "tenge",
      "tennis-ball",
      "terminal",
      "text",
      "text-height",
      "text-size",
      "text-width",
      "th",
      "th-large",
      "th-list",
      "theater-masks",
      "thermometer",
      "thermometer-empty",
      "thermometer-full",
      "thermometer-half",
      "thermometer-quarter",
      "thermometer-three-quarters",
      "theta",
      "thumbs-down",
      "thumbs-up",
      "thumbtack",
      "thunderstorm",
      "thunderstorm-moon",
      "thunderstorm-sun",
      "ticket",
      "ticket-alt",
      "tilde",
      "times",
      "times-circle",
      "times-hexagon",
      "times-octagon",
      "times-square",
      "tint",
      "tint-slash",
      "tire",
      "tire-flat",
      "tire-pressure-warning",
      "tire-rugged",
      "tired",
      "toggle-off",
      "toggle-on",
      "toilet",
      "toilet-paper",
      "toilet-paper-alt",
      "toilet-paper-slash",
      "tombstone",
      "tombstone-alt",
      "toolbox",
      "tools",
      "tooth",
      "toothbrush",
      "torah",
      "torii-gate",
      "tornado",
      "tractor",
      "trademark",
      "traffic-cone",
      "traffic-light",
      "traffic-light-go",
      "traffic-light-slow",
      "traffic-light-stop",
      "trailer",
      "train",
      "tram",
      "transgender",
      "transgender-alt",
      "transporter",
      "transporter-1",
      "transporter-2",
      "transporter-3",
      "transporter-empty",
      "trash",
      "trash-alt",
      "trash-restore",
      "trash-restore-alt",
      "trash-undo",
      "trash-undo-alt",
      "treasure-chest",
      "tree",
      "tree-alt",
      "tree-christmas",
      "tree-decorated",
      "tree-large",
      "tree-palm",
      "trees",
      "triangle",
      "triangle-music",
      "trophy",
      "trophy-alt",
      "truck",
      "truck-container",
      "truck-couch",
      "truck-loading",
      "truck-monster",
      "truck-moving",
      "truck-pickup",
      "truck-plow",
      "truck-ramp",
      "trumpet",
      "tshirt",
      "tty",
      "turkey",
      "turntable",
      "turtle",
      "tv",
      "tv-alt",
      "tv-music",
      "tv-retro",
      "typewriter",
      "ufo",
      "ufo-beam",
      "umbrella",
      "umbrella-beach",
      "underline",
      "undo",
      "undo-alt",
      "unicorn",
      "union",
      "universal-access",
      "university",
      "unlink",
      "unlock",
      "unlock-alt",
      "upload",
      "usb-drive",
      "usd-circle",
      "usd-square",
      "user",
      "user-alien",
      "user-alt",
      "user-alt-slash",
      "user-astronaut",
      "user-chart",
      "user-check",
      "user-circle",
      "user-clock",
      "user-cog",
      "user-cowboy",
      "user-crown",
      "user-edit",
      "user-friends",
      "user-graduate",
      "user-hard-hat",
      "user-headset",
      "user-injured",
      "user-lock",
      "user-md",
      "user-md-chat",
      "user-minus",
      "user-music",
      "user-ninja",
      "user-nurse",
      "user-plus",
      "user-robot",
      "user-secret",
      "user-shield",
      "user-slash",
      "user-tag",
      "user-tie",
      "user-times",
      "user-unlock",
      "user-visor",
      "users",
      "users-class",
      "users-cog",
      "users-crown",
      "users-medical",
      "users-slash",
      "utensil-fork",
      "utensil-knife",
      "utensil-spoon",
      "utensils",
      "utensils-alt",
      "vacuum",
      "vacuum-robot",
      "value-absolute",
      "vector-square",
      "venus",
      "venus-double",
      "venus-mars",
      "vest",
      "vest-patches",
      "vhs",
      "vial",
      "vials",
      "video",
      "video-plus",
      "video-slash",
      "vihara",
      "violin",
      "virus",
      "virus-slash",
      "viruses",
      "voicemail",
      "volcano",
      "volleyball-ball",
      "volume",
      "volume-down",
      "volume-mute",
      "volume-off",
      "volume-slash",
      "volume-up",
      "vote-nay",
      "vote-yea",
      "vr-cardboard",
      "wagon-covered",
      "walker",
      "walkie-talkie",
      "walking",
      "wallet",
      "wand",
      "wand-magic",
      "warehouse",
      "warehouse-alt",
      "washer",
      "watch",
      "watch-calculator",
      "watch-fitness",
      "water",
      "water-lower",
      "water-rise",
      "wave-sine",
      "wave-square",
      "wave-triangle",
      "waveform",
      "waveform-path",
      "webcam",
      "webcam-slash",
      "weight",
      "weight-hanging",
      "whale",
      "wheat",
      "wheelchair",
      "whistle",
      "wifi",
      "wifi-1",
      "wifi-2",
      "wifi-slash",
      "wind",
      "wind-turbine",
      "wind-warning",
      "window",
      "window-alt",
      "window-close",
      "window-frame",
      "window-frame-open",
      "window-maximize",
      "window-minimize",
      "window-restore",
      "windsock",
      "wine-bottle",
      "wine-glass",
      "wine-glass-alt",
      "won-sign",
      "wreath",
      "wrench",
      "x-ray",
      "yen-sign",
      "yin-yang"
    ],
    paymentDecorators: [],
    popular: [
      'building',
      'store',
      'hotel',
      'landmark',
      'landmark-dome',
      'shop',
      'warehouse',
      'warehouse-full',
      'apartment',
      'city',
      'receipt',
      'bag-shopping',
      'burger-soda',
      'plate-utensils',
      'spa',
      'screwdriver-wrench',
      'basket-shopping',
      'cart-shopping',
      'bell-concierge',
      'flower-tulip',
      'dice-three',
      'slot-machine',
      'ticket',
      'masks-theater'
    ]
  },

  MediaFileFolders: {
    WalletConfiguration: "wallet_configuration",
    BlogAndNews: "blog_and_news",
    Events: "events",
    PromoCodes: "promo_codes",
    ImageGrid: "image_grid",
    HelpDesk: "help_desk",
    CreateNewSMS: "communications_sms_create",
    SendVouchers: "communications_sms_voucher_campaigns",
    PaymentDesigns: "payment_configuration_design",
    CustomizeQRCode: "customize-qr-code",

    Amenities: "amenities",
    Products: "products",
    RoomRates: "room-rates",
    Dining: "dining",
    Gaming: "gaming",
    Lounge: "lounge",
    Services: "services",
  }
};
Common.Icons.light = Common.Icons.regular;
Common.Icons.solid = Common.Icons.regular;
Common.Icons.thin = Common.Icons.regular;
Common.Icons.paymentDecorators = Common.Icons.regular.concat(Common.Icons.brands);



/*
staticVoucher.value_integer = y; //to be used under circumstances A, B & C.
staticVoucher.value_float = a; //to be used under circumstances D, E & F.
staticVoucher.value_string = z; //to be used under circumstances G & H
staticVoucher.value_string_encoded = z; //to be used under circumstances I & J
staticVoucher.value_string_currency = z; //to be used under circumstances I & J
*/


